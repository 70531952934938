import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import { useCustom } from '@medical/provider/context'
import Auth from '@medical/middleware/auth'
import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Link } from 'react-router-dom'
import ClinicsManageHome from './ClinicsManageHome'
import ClinicsManageCreateClinic from './ClinicsManageCreateClinic'
import ClinicsManageScript from './ClinicsManageScript'
import ClinicsManageUpdate from './ClinicsManageUpdate'
import Router from '@medical/routes/router'
import {
  CLINIC_LIST,
  CREATE_CLINIC,
  STAFF_ME,
  UPDATE_CLINIC,
} from './ClinicsManage.graphql'
import moment from 'moment'

const ClinicsManage = () => {
  const [results, setResults] = useState([])

  const [{ i18n, popup }] = useCustom()
  const staffHasPermissionEmail = [
    'medical-dev02-ml@goldware.jp',
    'capsdoctor365@gmail.com',
  ]
  const listState = {
    state0: 0,
    state1: 1,
    state2: 2,
    state3: 3,
  }
  const [currentState, setCurrentState] = useState(listState.state0)
  const { loading, error, data } = useQuery(STAFF_ME)
  const {
    loading: clinicsLoading,
    error: clinicsError,
    data: clinicsData,
    refetch,
  } = useQuery(CLINIC_LIST, { variables: { where: { deletedAt: null } } })
  const [createClinic] = useMutation(CREATE_CLINIC)
  const [updateClinic] = useMutation(UPDATE_CLINIC)
  const token = Auth.getToken()
  useEffect(() => {
    refetch()
  })

  if (loading || clinicsLoading) return <ProgressSpinner />
  if (error || clinicsError)
    return <ErrorComponent error={error || clinicsError} />
  const staffEmail = data?.staffMe.email
  const corporations = data?.corporations
  const clinics = clinicsData?.clinics || []
  if (!staffHasPermissionEmail.includes(staffEmail)) {
    return (
      <div className='static-container'>
        <div className='static-title'>エラー</div>
        <div className='static-content'>{i18n.t('main.noPermissionToSee')}</div>
        <Link to={Router.staffHome}>
          <Button
            label={i18n.t('main.pageNotFoundButton')}
            className='static-button'
          />
        </Link>
      </div>
    )
  }

  const onCreateClinic = async (values, { setSubmitting, resetForm }) => {
    const {
      internalMedicineCode,
      isDisplayInternalMedicine,
      childrenMedicineCode,
      isDisplayChildrenMedicine,
      childrenVaccineCode,
      isDisplayChildrenVaccine,
      internalVaccineCode,
      isDisplayInternalVaccine,
      isCreateInternalMedicine,
      isCreateChildrenMedicine,
      isCreateChildrenVaccine,
      isCreateInternalVaccine,
    } = values
    const clinicalDepartmentCreate = []
    if (isCreateInternalMedicine)
      clinicalDepartmentCreate.push({
        name: 'INTERNAL_MEDICINE',
        departmentCode: internalMedicineCode,
        isDisplay: !!isDisplayInternalMedicine,
      })
    if (isCreateChildrenMedicine)
      clinicalDepartmentCreate.push({
        name: 'CHILDREN_MEDICINE',
        departmentCode: childrenMedicineCode,
        isDisplay: !!isDisplayChildrenMedicine,
      })
    if (isCreateChildrenVaccine)
      clinicalDepartmentCreate.push({
        name: 'CHILDREN_VACCINE_MEDICINE',
        departmentCode: childrenVaccineCode,
        isDisplay: !!isDisplayChildrenVaccine,
      })
    if (isCreateInternalVaccine)
      clinicalDepartmentCreate.push({
        name: 'INTERNAL_VACCINE_MEDICINE',
        departmentCode: internalVaccineCode,
        isDisplay: !!isDisplayInternalVaccine,
      })

    try {
      await createClinic({
        variables: {
          clinicName: values.clinicName,
          address: values.address,
          email: values.email,
          nameKana: values.clinicNameKana,
          order: Number(values.order),
          startTimeMonday: moment(
            `1000-10-10T${moment(values.startTimeMonday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeMonday: moment(
            `1000-10-10T${moment(values.endTimeMonday).format('HH:mm')}:00.000Z`
          ).toISOString(),
          isAvailableOnMonday: values.isAvailableOnMonday || false,
          startTimeTuesday: moment(
            `1000-10-10T${moment(values.startTimeTuesday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeTuesday: moment(
            `1000-10-10T${moment(values.endTimeTuesday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          isAvailableOnTuesday: values.isAvailableOnTuesday || false,
          startTimeWednesday: moment(
            `1000-10-10T${moment(values.startTimeWednesday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeWednesday: moment(
            `1000-10-10T${moment(values.endTimeWednesday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          isAvailableOnWednesday: values.isAvailableOnWednesday || false,
          startTimeThursday: moment(
            `1000-10-10T${moment(values.startTimeThursday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeThursday: moment(
            `1000-10-10T${moment(values.endTimeThursday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          isAvailableOnThursday: values.isAvailableOnThursday || false,
          startTimeFriday: moment(
            `1000-10-10T${moment(values.startTimeFriday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeFriday: moment(
            `1000-10-10T${moment(values.endTimeFriday).format('HH:mm')}:00.000Z`
          ).toISOString(),
          isAvailableOnFriday: values.isAvailableOnFriday || false,
          startTimeSaturday: moment(
            `1000-10-10T${moment(values.startTimeSaturday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeSaturday: moment(
            `1000-10-10T${moment(values.endTimeSaturday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          isAvailableOnSaturday: values.isAvailableOnSaturday || false,
          startTimeSunday: moment(
            `1000-10-10T${moment(values.startTimeSunday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeSunday: moment(
            `1000-10-10T${moment(values.endTimeSunday).format('HH:mm')}:00.000Z`
          ).toISOString(),
          isAvailableOnSunday: values.isAvailableOnSunday || false,
          startTimeHoliday: moment(
            `1000-10-10T${moment(values.startTimeHoliday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          endTimeHoliday: moment(
            `1000-10-10T${moment(values.endTimeHoliday).format(
              'HH:mm'
            )}:00.000Z`
          ).toISOString(),
          isAvailableOnHoliday: values.isAvailableOnHoliday || false,
          isPublished: !!values.isPublished,
          isOutside: !!values.isOutside,
          messageIntroduce: values.messageIntroduce,
          type: values.type,
          nearestStations: values.nearestStations,
          isSearchDisplay: !!values.isSearchDisplay,
          corporationId: values.corporationId,
          clinicalDepartments: clinicalDepartmentCreate,
        },
      })
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const onUpdateClinic = async (values, { setSubmitting, resetForm }) => {
    const {
      clinicId,
      internalCode,
      isDisplayInternal,
      isCreateInternal,
      isUpdateInternal,
      childrenCode,
      isDisplayChildren,
      isUpdateChildren,
      isCreateChildren,
      internalVaccineCode,
      isDisplayInternalVaccine,
      isCreateInternalVaccine,
      isUpdateInternalVaccine,
      childrenVaccineCode,
      isDisplayChildrenVaccine,
      isUpdateChildrenVaccine,
      isCreateChildrenVaccine,
    } = values
    try {
      await updateClinic({
        variables: {
          clinicId,
          isCreateInternal: isCreateInternal && isUpdateInternal,
          isCreateChildren: isCreateChildren && isUpdateChildren,
          isCreateInternalVaccine:
            isCreateInternalVaccine && isUpdateInternalVaccine,
          isCreateChildrenVaccine:
            isCreateChildrenVaccine && isUpdateChildrenVaccine,
          isUpdateInternal: isUpdateInternal || false,
          isUpdateChildren: isUpdateChildren || false,
          isUpdateChildrenVaccine: isUpdateChildrenVaccine || false,
          isUpdateInternalVaccine: isUpdateInternalVaccine || false,
          internalCode,
          isDisplayInternal: isDisplayInternal || false,
          childrenCode,
          isDisplayChildren: isDisplayChildren || false,
          internalVaccineCode,
          isDisplayInternalVaccine: isDisplayInternalVaccine || false,
          childrenVaccineCode,
          isDisplayChildrenVaccine: isDisplayChildrenVaccine || false,
        },
      })
      resetForm()
      refetch()
      popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      setSubmitting(false)
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const runScript = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true)
      const response = await fetch(
        `${process.env.REACT_APP_ENDPOINT}/run-script?token=${token}&scriptName=${values.scriptName}&clinicId=${values.clinicId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      const data = await response.json()
      console.log(data, 'data')

      if (data.success) {
        setResults(prevResults => [
          ...prevResults,
          { scriptName: values.scriptName, count: data.number },
        ])
        popup.success(i18n.t('staff.updateClinic.submissionSuccess'))
      } else {
        popup.error(i18n.t('staff.updateClinic.submissionError'))
      }
      return data
    } catch (error) {
      popup.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const renderByState = state => {
    switch (state) {
      case listState.state0:
        return (
          <ClinicsManageHome
            i18n={i18n}
            listState={listState}
            setCurrentState={setCurrentState}
          />
        )

      case listState.state1:
        return (
          <>
            <ClinicsManageCreateClinic
              i18n={i18n}
              listState={listState}
              setCurrentState={setCurrentState}
              onCreateClinic={onCreateClinic}
              corporations={corporations}
            />
          </>
        )

      case listState.state2:
        return (
          <>
            <ClinicsManageUpdate
              i18n={i18n}
              listState={listState}
              setCurrentState={setCurrentState}
              clinics={clinics}
              onUpdateClinic={onUpdateClinic}
            />
          </>
        )

      case listState.state3:
        return (
          <>
            <ClinicsManageScript
              i18n={i18n}
              listState={listState}
              setCurrentState={setCurrentState}
              runScript={runScript}
              clinics={clinics}
              results={results}
            />
          </>
        )
      default:
        return null
    }
  }
  return <>{renderByState(currentState)}</>
}

export default ClinicsManage
