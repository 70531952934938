import './style.css'

import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/react-hooks'
import { INQUIRY_SENDER, ROW_PER_PAGE, SCROLL_TYPE } from '@medical/constant'
import { useCustom } from '@medical/provider/context'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  CONVERSATION_CONTENT_CONNECTION_SUBSCRIPTION,
  CREATE_MESSAGE,
  GET_CONVERSATION_CONTENTS,
  GET_MESSAGES_BY_INDEX,
  MARK_AS_READ,
  SEARCH_IN_CONVERSATION,
} from './Inquiry.graphql'
import InquiryChatForm from './InquiryChatForm'

const InquiryScene = ({
  i18n,
  doctorId,
  conversationId,
  inquirySender,
  doctorName,
  isReplyPermitted,
  setConversationId,
  isRecruitment,
  title,
  type,
  clinicalDepartment,
  setDoctorName,
  conversationStatus,
  setConversationStatus,
  updateConversationStatus,
}) => {
  const [{ popup }] = useCustom()
  const client = useApolloClient()
  const [recruitment, setRecruitment] = useState(isRecruitment)
  const [inquiryMessages, setInquiryMessages] = useState([])
  const [searchText, setSearchText] = useState('')
  const [index, setIndex] = useState(-1)
  const [first] = useState(parseInt(ROW_PER_PAGE, 10))
  const [skip, setSkip] = useState(0)
  const [intervalMessages, setIntervalMessages] = useState([])
  let lastestIndex = -1
  const variables = {
    orderBy: 'createdAt_DESC',
    first,
    skip,
    where: {
      Conversation: {
        id: conversationId || '',
      },
    },
  }
  // const chatContentsInterval = useRef(null)
  const [createMessage] = useMutation(CREATE_MESSAGE)
  const [markAsRead] = useMutation(MARK_AS_READ)

  const { error: searchError, data: searchData } = useQuery(
    SEARCH_IN_CONVERSATION,
    {
      variables: {
        where: {
          Conversation: {
            id: conversationId,
          },
          AND: [
            {
              message_contains: searchText,
            },
          ],
        },
        orderBy: 'createdAt_DESC',
      },
      skip: !searchText || searchText.length === 0,
      fetchPolicy: 'network-only',
    }
  )
  const getConversationContents = async (offset = 0, limit = ROW_PER_PAGE) => {
    try {
      const {
        data: {
          conversationContentsConnection: { edges },
        },
      } = await client.query({
        query: GET_CONVERSATION_CONTENTS,
        variables: {
          ...variables,
          first: limit,
          skip: offset,
        },
        fetchPolicy: 'network-only',
      })
      return edges
    } catch (error) {
      // popup.error(error)
      const edges = []
      return edges
    }
  }
  const { data: lastestIndexData, refetch: lastestIndexRefetch } = useQuery(
    GET_CONVERSATION_CONTENTS,
    {
      variables: {
        orderBy: 'createdAt_DESC',
        first: 1,
        skip: 0,
        where: {
          Conversation: {
            id: conversationId,
          },
        },
      },
    }
  )
  if (
    lastestIndexData &&
    lastestIndexData.conversationContentsConnection &&
    lastestIndexData.conversationContentsConnection.edges.length > 0
  ) {
    lastestIndex =
      lastestIndexData.conversationContentsConnection.edges[0].node.index
  }
  useSubscription(CONVERSATION_CONTENT_CONNECTION_SUBSCRIPTION, {
    variables: { conversationId: conversationId || '' },
    onSubscriptionData: () => {
      async function getMsg() {
        const edges = await getConversationContents()
        setIntervalMessages(edges)
      }
      if (index < 0) {
        getMsg()
      }
      lastestIndexRefetch()
    },
  })
  if (searchError) popup.error(searchError)
  const tempChatContent = {
    id: -1,
    conversationId: conversationId || '',
    message: '',
    isCreatedByStaff: inquirySender === INQUIRY_SENDER.STAFF,
    imageKey: null,
    createdAt: moment(new Date()).format(),
    deletedAt: null,
    updatedAt: moment(new Date()).format(),
    index: -1,
    isRead: true,
    imageURL: null,
  }

  const onSubmit = async (e, { setSubmitting, resetForm }) => {
    try {
      await createMessage({
        variables: {
          message: e.message.trim(),
          conversationId,
          doctorId,
          imageKey: e.imageKey,
        },
      })

      const tempImage = e.file
        ? [
            {
              node: {
                ...tempChatContent,
                imageKey: e.file.name,
                imageURL: e.file,
              },
            },
          ]
        : []

      const tempMessages =
        e.message && e.message.length > 0
          ? [
              {
                node: {
                  ...tempChatContent,
                  message: e.message.trim(),
                },
              },
            ]
          : []
      setInquiryMessages([...tempImage, ...tempMessages, ...inquiryMessages])
      setSubmitting(false)
      if (recruitment) setRecruitment(false)
      resetForm()
    } catch (error) {
      setSubmitting(false)
      if (recruitment) setRecruitment(false)
    }
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     lastestIndexRefetch()
  //   }, 5000000000)
  //   return () => clearInterval(interval)
  // }, [lastestIndexRefetch])

  const handleMarkAsRead = async () => {
    if (index > 0) {
      setSearchText('')
      setIndex(-1)
      setSkip(0)
      const edges = await getConversationContents()
      setInquiryMessages([...edges])
      document.getElementById('scrollingDiv').scrollTop = 0
    }
    if (index < 0) {
      document.getElementById('scrollingDiv').scrollTop = 0
    }
    if (conversationId) {
      await markAsRead({
        variables: {
          conversationId,
        },
      })
    }
  }

  const getMoreMessage = async scrollType => {
    try {
      if (index < 0) {
        const edges = await getConversationContents(skip + ROW_PER_PAGE)
        setInquiryMessages([...inquiryMessages, ...edges])
        setSkip(skip + ROW_PER_PAGE)
      } else if (index > 0) {
        let startIndex = 0
        let endIndex = 0
        if (scrollType === SCROLL_TYPE.SCROLL_UP) {
          const lastIndex =
            inquiryMessages[inquiryMessages.length - 1].node.index
          endIndex = lastIndex - 1
          startIndex =
            endIndex - ROW_PER_PAGE * 2 > 0 ? endIndex - ROW_PER_PAGE * 2 : 0
          setIndex(startIndex === 0 ? 1 : endIndex)
        } else {
          const firstIndex = inquiryMessages[0].node.index
          startIndex = firstIndex + 1
          endIndex = startIndex + ROW_PER_PAGE * 2
        }
        const {
          data: {
            conversationContentsConnection: { edges },
          },
        } = await client.query({
          query: GET_MESSAGES_BY_INDEX,
          variables: {
            where: {
              Conversation: {
                id: conversationId,
              },
              index_gte: startIndex,
              index_lte: endIndex,
              deletedAt: null,
            },
            orderBy: 'createdAt_DESC',
          },
          fetchPolicy: 'network-only',
        })
        if (scrollType === SCROLL_TYPE.SCROLL_UP) {
          setInquiryMessages([...inquiryMessages, ...edges])
        } else {
          setInquiryMessages([...edges, ...inquiryMessages])
        }
      }
    } catch (error) {
      popup.error(error)
    }
  }

  const getMessagesByIndex = async selectedIndex => {
    try {
      const {
        data: {
          conversationContentsConnection: { edges },
        },
      } = await client.query({
        query: GET_MESSAGES_BY_INDEX,
        variables: {
          where: {
            Conversation: {
              id: conversationId,
            },
            index_gte:
              selectedIndex >= ROW_PER_PAGE ? selectedIndex - ROW_PER_PAGE : 0,
            index_lte: selectedIndex + ROW_PER_PAGE,
            deletedAt: null,
          },
          orderBy: 'createdAt_DESC',
        },
        fetchPolicy: 'network-only',
      })
      // clearInterval(chatContentsInterval.current)
      setInquiryMessages([...edges])
      setIndex(selectedIndex)
    } catch (error) {
      popup.error(error)
    }
  }

  const getMappingInquiryMessages = data => {
    const ids = new Set(inquiryMessages.map(({ node }) => node.id))
    const filters = data.filter(({ node }) => !ids.has(node.id))
    const merged = [
      ...filters,
      ...inquiryMessages.filter(({ node }) => node.id !== -1),
    ].sort((a, b) => moment(a.node.createdAt) < moment(b.node.createdAt))

    return merged
  }

  useEffect(() => {
    if (intervalMessages && intervalMessages.length) {
      setInquiryMessages(getMappingInquiryMessages(intervalMessages))
      setIntervalMessages([])
    }
  }, [intervalMessages])

  useEffect(() => {
    setSearchText('')
    setSkip(0)
  }, [doctorId])

  useEffect(() => {
    async function fetchMyAPI() {
      const edges = await getConversationContents()
      setInquiryMessages([...edges])
      if (inquirySender === INQUIRY_SENDER.STAFF && edges.length > 0) {
        setDoctorName(
          `${edges[0].node.conversation.doctor.lastname} ${edges[0].node.conversation.doctor.firstname}`
        )
      }
    }
    fetchMyAPI()
    // chatContentsInterval.current = setInterval(async () => {
    //   const edges = await getConversationContents()
    //   setIntervalMessages(edges)
    // }, 5000)
    // return () => clearInterval(chatContentsInterval.current)
  }, [conversationId])
  return (
    <div className='container is-max'>
      <InquiryChatForm
        i18n={i18n}
        onSubmit={onSubmit}
        inquiryMessages={inquiryMessages}
        index={index}
        setIndex={setIndex}
        searchText={searchText}
        setSearchText={setSearchText}
        searchData={searchData}
        handleMarkAsRead={handleMarkAsRead}
        getMoreMessage={getMoreMessage}
        inquirySender={inquirySender}
        getMessagesByIndex={getMessagesByIndex}
        doctorName={doctorName}
        isReplyPermitted={isReplyPermitted}
        doctorId={doctorId}
        setConversationId={setConversationId}
        lastestIndex={lastestIndex}
        isRecruitment={recruitment}
        title={title}
        type={type}
        clinicalDepartment={clinicalDepartment}
        conversationId={conversationId}
        conversationStatus={conversationStatus}
        setConversationStatus={setConversationStatus}
        updateConversationStatus={updateConversationStatus}
      />
    </div>
  )
}

export default InquiryScene
