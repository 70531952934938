import './style.css'

import { useQuery, useMutation } from '@apollo/react-hooks'
import { ErrorComponent, ProgressSpinner } from '@medical/components'
import CustomCheckBox from '@medical/components/CheckBoxSignup'
import CustomDropdown from '@medical/components/DropdownForSignup'
import { ACCEPT_FILE_EXTENSIONS, MAX_FILE_SIZE } from '@medical/constant'
import { generateOptions, OPTION_TYPES } from '@medical/constant/utilities'
import { ErrorMessage, FieldArray, Formik } from 'formik'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { useState } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Dropdown } from '../../../../fork/primereact/dropdown'
import { SKILL_CONNECTIONS } from './signup.grapql'
import { useCustom } from '@medical/provider/context'
import { GET_POST_PRE_SIGNED_URL } from '../UploadImage/UploadImage.graphql'
import uploadImage from '@medical/libs/uploadImage'
import { Radio, Space, TimePicker, Checkbox as CheckboxAntd } from 'antd'
import Calendar from '@medical/components/Calendar'
import moment from 'moment'
import _ from 'lodash'
import { DATE_ORDER } from '@medical/pages/staff/Doctors/DoctorDetail/DoctorInterviewSchedule'
import { isMobile } from 'react-device-detect'

const SignupComponentStep3 = ({
  handleBackStep,
  handleSubmitSignup,
  stateData,
}) => {
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [filename, setFilename] = useState()
  const [graduationUniversityYear, setGraduationUniversityYear] = useState(
    stateData.graduationUniversityYear
      ? stateData.graduationUniversityYear
      : 2000
  )
  const [obtainDoctorLicenseYear, setObtainDoctorLicenseYear] = useState(
    stateData.obtainDoctorLicenseYear ? stateData.obtainDoctorLicenseYear : 2000
  )
  const [{ i18n, popup }] = useCustom()
  const [getPostPreSignedUrl] = useMutation(GET_POST_PRE_SIGNED_URL)
  const { loading, error, data } = useQuery(SKILL_CONNECTIONS, {
    where: {
      isShow: true,
    },
  })
  if (loading) return <ProgressSpinner />
  if (error) return <ErrorComponent error={error} />
  const departments = [
    {
      value: 'INTERNAL_MEDICINE',
      description: `${i18n.t('doctor.signup.internalMedicine')}`,
    },
    {
      value: 'CHILDREN_MEDICINE',
      description: `${i18n.t('doctor.signup.childrenMedicine')}`,
    },
  ]
  const isIntroduce = [
    {
      value: '1',
      description: `${i18n.t('doctor.signup.yesLabel')}`,
    },
    {
      value: '0',
      description: `${i18n.t('doctor.signup.noLabel')}`,
    },
  ]
  const years = generateOptions(OPTION_TYPES.YEAR, 100, 30)

  const listMonth = generateOptions(OPTION_TYPES.MONTH)
  const reasons = [
    {
      value: 'FLYER',
      description: `${i18n.t('doctor.signup.flyer')}`,
    },
    {
      value: 'FROM_FRIEND',
      description: `${i18n.t('doctor.signup.fromFriend')}`,
    },
    {
      value: 'HOMEPAGE',
      description: `${i18n.t('doctor.signup.homePage')}`,
    },
    {
      value: 'INTERNET',
      description: `${i18n.t('doctor.signup.internet')}`,
    },
    {
      value: 'ASK_STAFF',
      description: `${i18n.t('doctor.signup.askStaff')}`,
    },
    {
      value: 'OTHER',
      description: `${i18n.t('doctor.signup.other')}`,
    },
  ]

  const isCompletedOption = [
    {
      value: true,
      description: `${i18n.t('doctor.signup.doneLabel')}`,
    },
    {
      value: false,
      description: `${i18n.t('doctor.signup.yetLabel')}`,
    },
  ]
  const findIndex = (arr, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        return i
        break
      }
    }
    return 'noIndex'
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{
        departments: stateData.departments,
        graduationUniversity: stateData.graduationUniversity,
        graduationUniversityYear: stateData.graduationUniversityYear
          ? stateData.graduationUniversityYear
          : 2000,
        obtainDoctorLicenseYear: stateData.obtainDoctorLicenseYear
          ? stateData.obtainDoctorLicenseYear
          : 2000,
        professionalDescription: stateData.professionalDescription,
        // medicalLicenseNumber: stateData.medicalLicenseNumber,
        isIntroduce: stateData.isIntroduce,
        introducedBy: stateData.introducedBy,
        file: stateData.file,
        confirm: stateData.confirm,
        isJoinedSublingualImmunotherapyELearning:
          stateData.isJoinedSublingualImmunotherapyELearning,
        isJoinedOnlineDiagnosticTraining:
          stateData.isJoinedOnlineDiagnosticTraining,
        reasonKnowWebsites: stateData.reasonKnowWebsites,
        otherReasonKnowWebsite: stateData.otherReasonKnowWebsite,
        careerHistory: stateData.careerHistory,
        skillSet: stateData.skillSet,
        filename: stateData?.filename,
        mimetype: stateData?.mimetype,
        expectedDateInterview: stateData.expectedDateInterview,
      }}
      validationSchema={Yup.object().shape({
        graduationUniversity: Yup.string().required(
          ` ${i18n.t('doctor.signup.graduationUniversityLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        departments: Yup.array()
          .of(Yup.string())
          .min(1, i18n.t('validation.departments.required'))
          .required(
            `${i18n.t('updateProfile.departments')}${i18n.t(
              'validation.required'
            )}`
          ),
        graduationUniversityYear: Yup.string().required(
          `${i18n.t('doctor.signup.graduationUniversityYearLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        obtainDoctorLicenseYear: Yup.string().required(
          `${i18n.t('doctor.signup.obtainDoctorLicenseYearsLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        professionalDescription: Yup.string().nullable(),
        // medicalLicenseNumber: Yup.string().nullable(),
        isIntroduce: Yup.string().required(
          `${i18n.t('doctor.signup.isIntroduceLabel')}${i18n.t(
            'validation.required'
          )}`
        ),
        introducedBy: Yup.string().when('isIntroduce', {
          is: '1',
          then: Yup.string().required(
            i18n.t('doctor.signup.introduceNameInput')
          ),
        }),
        reasonKnowWebsites: Yup.string(),
        otherReasonKnowWebsite: Yup.string().when(
          'reasonKnowWebsites',
          reasonKnowWebsites =>
            reasonKnowWebsites.includes('OTHER')
              ? Yup.string().required(i18n.t('doctor.signup.inputMessage'))
              : Yup.string().nullable()
        ),
        careerHistory: Yup.array().of(
          Yup.object().shape({
            description: Yup.string().required(
              i18n.t('validation.requireDescription')
            ),
            month: Yup.string().required(i18n.t('validation.requireMonth')),
            year: Yup.string().required(i18n.t('validation.requireYear')),
          })
        ),
        expectedDateInterview: Yup.array().of(
          Yup.object().shape({
            date: Yup.string().required('面談日付を選択してください。'),
            expectTime: Yup.array().of(Yup.string()),
            selectTime: Yup.number().oneOf([1, 2]),
            startTime: Yup.string().when('expectTime', {
              is: expectTime => expectTime.length === 0,
              then: Yup.string().required('面談時間を選択してください。'),
            }),
            endTime: Yup.string().when('expectTime', {
              is: expectTime => expectTime.length === 0,
              then: Yup.string().required('面談時間を選択してください。'),
            }),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { expectedDateInterview } = values
        const interviewTime = expectedDateInterview.map(date => {
          if (date.selectTime === 2) {
            return {
              date: date.date,
              expectTime: date.expectTime,
              type: 'SELECT',
            }
          } else if (date.selectTime === 1) {
            return {
              date: date.date,
              expectTime: [
                `${moment(date.startTime).format('HH:mm')}～${moment(
                  date.endTime
                ).format('HH:mm')}`,
              ],
              type: 'DEFAULT',
            }
          }
        })

        const temp = {
          ...values,
          // medicalLicenseNumber:
          //   values.medicalLicenseNumber === ''
          //     ? null
          //     : values.medicalLicenseNumber,
          professionalDescription:
            values.professionalDescription === ''
              ? null
              : values.professionalDescription,
          introducedBy: values.introducedBy === '' ? null : values.introducedBy,
          file: values.file === '' ? null : values.file,
          interviewTime: JSON.stringify(interviewTime),
        }
        delete temp.expectedDateInterview

        handleSubmitSignup(temp, { setSubmitting })
      }}
      render={formikProps => {
        const {
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
          touched,
          errors,
        } = formikProps
        return (
          <div>
            <form>
              <div className='signup-inner'>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.medicalSubjectLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='departments'
                    options={departments}
                    required
                  />
                  <ErrorMessage
                    name='departments'
                    component='div'
                    className='signup-alert'
                  />
                </div>

                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.graduationUniversityLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.graduationUniversity}
                    name='graduationUniversity'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name='graduationUniversity'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='signup-label required-label'>
                      {i18n.t('doctor.signup.graduationUniversityYearLabel')}
                    </div>
                    <div className='signup-input-half'>
                      <Dropdown
                        value={graduationUniversityYear}
                        name='graduationUniversityYear'
                        options={years}
                        optionLabel='description'
                        onChange={e => {
                          setFieldValue('graduationUniversityYear', e.value)
                          setGraduationUniversityYear(e.value)
                        }}
                        placeholder='2000'
                      />
                    </div>
                    <div className='alert'>
                      {!!touched.graduationUniversityYear &&
                        errors.graduationUniversityYear}
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='signup-label required-label'>
                      {i18n.t('doctor.signup.obtainDoctorLicenseYearsLabel')}
                    </div>
                    <div className='signup-input-half'>
                      <Dropdown
                        value={obtainDoctorLicenseYear}
                        name='obtainDoctorLicenseYear'
                        options={years}
                        optionLabel='description'
                        onChange={e => {
                          setFieldValue('obtainDoctorLicenseYear', e.value)
                          setObtainDoctorLicenseYear(e.value)
                        }}
                        placeholder='2000'
                      />
                    </div>
                    <div className='alert'>
                      {!!touched.obtainDoctorLicenseYear &&
                        errors.obtainDoctorLicenseYear}
                    </div>
                  </div>
                </div>

                <div className='signup-label'>
                  {i18n.t('doctor.signup.professionalDescriptionLabel')}
                </div>
                <div className='signup-input'>
                  <InputTextarea
                    values={values}
                    value={values.professionalDescription}
                    name='professionalDescription'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoResize
                    rows={5}
                  />
                </div>

                {/* <div className='signup-label'>
                  {i18n.t('doctor.signup.medicalLicenseNumberLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.medicalLicenseNumber}
                    name='medicalLicenseNumber'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='medicalLicenseNumber'
                    component='div'
                    className='signup-alert'
                  />
                </div> */}
                <div className='signup-label'>
                  {i18n.t('doctor.signup.medicalLicenseImgLabel')}
                </div>
                <div className='signup-input'>
                  {loadingUpload ? (
                    <ProgressSpinner />
                  ) : (
                    <>
                      <Dropzone
                        onDrop={async files => {
                          if (files.length > 0) {
                            setLoadingUpload(true)
                            const {
                              data: postPreSignedUrl,
                            } = await getPostPreSignedUrl({
                              variables: {
                                fileName: files[0].name,
                                mimetype: files[0].type,
                              },
                            })
                            const key = await uploadImage(
                              i18n,
                              popup,
                              postPreSignedUrl,
                              files[0]
                            )
                            if (key) {
                              setFieldValue('file', key)
                              setFieldValue('confirm', true)
                              setFieldValue('filename', files[0].name)
                              setFieldValue('mimetype', files[0].type)
                              setFilename(files[0].name)
                            }
                            setLoadingUpload(false)
                          }
                        }}
                        accept={ACCEPT_FILE_EXTENSIONS}
                        maxSize={MAX_FILE_SIZE}
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            {!filename && (
                              <p>
                                {i18n.t(
                                  'doctor.signup.uploadMedicalLicenseImgLabel'
                                )}
                              </p>
                            )}
                            <ul>
                              <li key={values.file.name}>{filename}</li>
                            </ul>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}
                </div>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.isIntroduceLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='isIntroduce'
                    options={isIntroduce}
                    required
                  />
                  <ErrorMessage
                    name='isIntroduce'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.introduceNameLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={
                      values.isIntroduce[0] === '0' ? '' : values.introducedBy
                    }
                    name='introducedBy'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={
                      values.isIntroduce[0] === '0' || values.isIntroduce === ''
                    }
                  />
                  <ErrorMessage
                    name='introducedBy'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.reasonKnowWebsiteLabel')}
                </div>
                <div className='signup-input signup-reason'>
                  <CustomCheckBox
                    {...formikProps}
                    name='reasonKnowWebsites'
                    options={reasons}
                    required
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.reasonKnowWebsiteDescriptionLabel')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={
                      values.reasonKnowWebsites.includes('OTHER')
                        ? values.otherReasonKnowWebsite
                        : ''
                    }
                    name='otherReasonKnowWebsite'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!values.reasonKnowWebsites.includes('OTHER')}
                  />
                  <ErrorMessage
                    name='otherReasonKnowWebsite'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.elearningLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='isJoinedSublingualImmunotherapyELearning'
                    options={isCompletedOption}
                    required
                  />
                  <div className='course-description'>
                    {i18n.t('doctor.signup.elearningDescription')}
                    <Link
                      to='/#'
                      onClick={() => {
                        window.open(
                          'http://www.ait-e-learning.jp/index.php',
                          '_blank'
                        )
                      }}
                    >
                      {i18n.t('doctor.signup.elearningLink')}
                    </Link>
                  </div>
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.diagnosticTrainingLabel')}
                </div>
                <div className='signup-input'>
                  <CustomCheckBox
                    {...formikProps}
                    name='isJoinedOnlineDiagnosticTraining'
                    options={isCompletedOption}
                    required
                  />
                  <div className='course-description'>
                    {i18n.t('doctor.signup.diagnosticTrainingDescription')}
                    <Link
                      to='/#'
                      onClick={() => {
                        window.open(
                          'https://telemed-training.jp/entry',
                          '_blank'
                        )
                      }}
                    >
                      {i18n.t('doctor.signup.diagnosticTrainingLink')}
                    </Link>
                  </div>
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.careerHistoryLabel')}
                </div>
                <div className='career-history-area'>
                  <FieldArray
                    name='careerHistory'
                    render={arrayHelpers => (
                      <div>
                        {values.careerHistory.map((career, index) => (
                          <div key={index}>
                            <div className='signup-input career-history'>
                              <div className='row'>
                                <div className='year'>
                                  <CustomDropdown
                                    {...formikProps}
                                    name={`careerHistory[${index}].year`}
                                    parentName='careerHistory'
                                    childName='year'
                                    index={index}
                                    options={years}
                                    classDisabled
                                    required
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.year')}
                                </div>
                                <div className='month'>
                                  <CustomDropdown
                                    {...formikProps}
                                    parentName='careerHistory'
                                    name={`careerHistory[${index}].month`}
                                    childName='month'
                                    index={index}
                                    options={listMonth}
                                    classDisabled
                                    required
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.month')}
                                </div>
                                <div className='description'>
                                  <InputTextarea
                                    {...formikProps}
                                    name={`careerHistory[${index}].description`}
                                    value={
                                      values.careerHistory[index].description
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoResize
                                    rows={1}
                                  />
                                </div>
                                <div className='col-1 tail-text'>
                                  {i18n.t('doctor.signup.experience')}
                                </div>
                                <div className='col-1'>
                                  <Button
                                    type='button'
                                    label='x'
                                    onClick={() => {
                                      arrayHelpers.remove(index)
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='error-career-history'>
                                <ErrorMessage
                                  name={`careerHistory[${index}].year`}
                                  component='div'
                                  className='signup-alert'
                                />
                                <ErrorMessage
                                  name={`careerHistory[${index}].month`}
                                  component='div'
                                  className='signup-alert'
                                />
                                <ErrorMessage
                                  name={`careerHistory[${index}].description`}
                                  component='div'
                                  className='signup-alert'
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                  <div className='row add-career-button'>
                    <Button
                      type='button'
                      onClick={() => {
                        setFieldValue('careerHistory', [
                          ...values.careerHistory,
                          {
                            year: '',
                            month: '',
                            description: '',
                          },
                        ])
                      }}
                      label={i18n.t('doctor.signup.addCareerButton')}
                    />
                  </div>
                </div>

                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.desiredDateForInterview')}
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.noteForSignup')}
                </div>
                <div style={{ marginBottom: '30px' }}>
                  <FieldArray
                    name='expectedDateInterview'
                    render={() => (
                      <div>
                        {values.expectedDateInterview.map((career, index) => {
                          return (
                            <div key={index}>
                              <div className='signup-input career-history mt-5'>
                                <p
                                  className='report-bold'
                                  style={{ fontSize: '16px' }}
                                >
                                  {DATE_ORDER[index]}
                                </p>

                                <div
                                  className='row'
                                  style={{
                                    justifyContent: 'end',
                                  }}
                                >
                                  <div>
                                    <div className='row mt-5'>
                                      <p className='col-5 report-bold'>
                                        {i18n.t('doctor.signup.selectDate')}
                                      </p>
                                      <div className='col-8'>
                                        <Calendar
                                          name={`expectedDateInterview[${index}].date`}
                                          value={career?.date || ''}
                                          onChange={e => {
                                            setFieldValue(
                                              `expectedDateInterview[${index}].date`,
                                              moment(e.value).format(
                                                'YYYY/MM/DD'
                                              )
                                            )
                                          }}
                                          showIcon
                                          minDate={moment().toDate()}
                                        />
                                      </div>
                                    </div>

                                    <div
                                      className='row'
                                      style={{
                                        marginBottom: 8,
                                      }}
                                    >
                                      <p className='col-5 report-bold'>
                                        {i18n.t(
                                          'doctor.signup.selectInterviewTime'
                                        )}
                                      </p>

                                      <Radio.Group
                                        value={
                                          values.expectedDateInterview[index]
                                            .selectTime
                                        }
                                        name={`expectedDateInterview[${index}].selectTime`}
                                        size='middle'
                                      >
                                        <Space direction='horizontal'>
                                          <Radio
                                            value={1}
                                            onClick={() => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].selectTime`,
                                                1
                                              )
                                            }}
                                            style={{
                                              width: '150%',
                                            }}
                                          >
                                            <span
                                              style={{
                                                width: '15%',
                                                marginRight: '16px',
                                                fontWeight: 'bold',
                                              }}
                                            >
                                              {i18n.t(
                                                'doctor.signup.specifyTime'
                                              )}
                                            </span>
                                          </Radio>
                                          <Radio
                                            value={2}
                                            onClick={() => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].selectTime`,
                                                2
                                              )
                                            }}
                                            style={{ width: '150%' }}
                                          >
                                            <span
                                              style={{
                                                marginRight: '8%',
                                                fontWeight: 'bold',
                                                whiteSpace: 'normal',
                                              }}
                                            >
                                              {i18n.t(
                                                'doctor.signup.specifyTimeZone'
                                              )}
                                            </span>
                                          </Radio>
                                        </Space>
                                      </Radio.Group>
                                    </div>

                                    {career.selectTime === 1 ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyItems: 'space-bettween',
                                          width: '100%',
                                          flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                          gap: isMobile ? 16 : 0,
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <p
                                            className='status-label'
                                            style={{ marginRight: '8px' }}
                                          >
                                            {i18n.t('doctor.signup.startTime')}
                                          </p>
                                          <TimePicker
                                            style={{ padding: 0 }}
                                            name={`expectedDateInterview[${index}].startTime`}
                                            showNow={false}
                                            hideDisabledOptions
                                            popupClassName='timepicker'
                                            value={
                                              values.expectedDateInterview[
                                                index
                                              ]?.startTime
                                            }
                                            format='HH:mm'
                                            placeholder=''
                                            onSelect={value => {
                                              if (value) {
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].startTime`,
                                                  value
                                                )
                                                const initialEndTime = moment(
                                                  value
                                                ).add(1, 'minutes')
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].endTime`,
                                                  initialEndTime
                                                )
                                              } else {
                                                setFieldValue(
                                                  `expectedDateInterview[${index}].startTime`,
                                                  null
                                                )
                                              }
                                            }}
                                            onChange={value => {
                                              setFieldValue(
                                                `expectedDateInterview[${index}].startTime`,
                                                value
                                              )
                                            }}
                                            defaultOpenValue={moment(
                                              '00:00',
                                              'HH:mm'
                                            )}
                                          />
                                        </div>

                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: isMobile ? 0 : 16,
                                          }}
                                        >
                                          <p
                                            className='status-label'
                                            style={{ marginRight: '8px' }}
                                          >
                                            {i18n.t('doctor.signup.endTime')}
                                          </p>
                                          <TimePicker
                                            style={{ padding: 0 }}
                                            name={`expectedDateInterview[${index}].endTime`}
                                            showNow={false}
                                            hideDisabledOptions
                                            popupClassName='timepicker'
                                            value={
                                              values.expectedDateInterview[
                                                index
                                              ]?.endTime
                                            }
                                            disabledHours={() => {
                                              const disabledHours = _.range(
                                                0,
                                                moment(
                                                  values
                                                    .expectedDateInterview?.[
                                                    index
                                                  ]?.startTime
                                                ).hour()
                                              )

                                              return disabledHours
                                            }}
                                            disabledMinutes={() => {
                                              const minuteOfStartTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.startTime
                                              ).hour()

                                              const minuteOfEndTime = moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.endTime
                                              ).hour()
                                              if (
                                                minuteOfStartTime ===
                                                minuteOfEndTime
                                              ) {
                                                const disabledHours = _.range(
                                                  0,
                                                  moment(
                                                    values
                                                      .expectedDateInterview?.[
                                                      index
                                                    ]?.startTime
                                                  ).minute() + 1
                                                )

                                                return disabledHours
                                              }
                                            }}
                                            format='HH:mm'
                                            placeholder=''
                                            onSelect={value => {
                                              value
                                                ? setFieldValue(
                                                    `expectedDateInterview[${index}].endTime`,
                                                    value
                                                  )
                                                : setFieldValue(
                                                    `expectedDateInterview[${index}].endTime`,
                                                    null
                                                  )
                                            }}
                                            onChange={value =>
                                              setFieldValue(
                                                `expectedDateInterview[${index}].endTime`,
                                                value
                                              )
                                            }
                                            defaultOpenValue={moment(
                                              moment(
                                                values.expectedDateInterview?.[
                                                  index
                                                ]?.startTime
                                              ).format('HH:mm'),
                                              'HH:mm'
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyItems: 'space-bettween',
                                          width: '100%',
                                        }}
                                      >
                                        <CheckboxAntd.Group
                                          value={
                                            values.expectedDateInterview[index]
                                              .expectTime
                                          }
                                          onChange={e => {
                                            setFieldValue(
                                              `expectedDateInterview[${index}].expectTime`,
                                              e
                                            )
                                          }}
                                          options={[
                                            '午前(9:00～13:00)',
                                            '午後(13:00～18:00)',
                                            '夜間(18:00～21:00)',
                                          ]}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className='error-career-history'>
                                  {errors?.expectedDateInterview?.[index]
                                    ?.date ? (
                                    <ErrorMessage
                                      name={`expectedDateInterview[${index}].date`}
                                      component='div'
                                      className='signup-alert'
                                    />
                                  ) : (
                                    <ErrorMessage
                                      name={`expectedDateInterview[${index}].startTime`}
                                      component='div'
                                      className='signup-alert'
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )}
                  />
                </div>

                {data.skillsConnection.edges.map(({ node }, index) => (
                  <div className='signup-input' key={index}>
                    <div className='signup-label'>{node.name}</div>
                    <div className='row'>
                      <div className='checkbox-container checkbox-field'>
                        <Checkbox
                          checked={values.skillSet.includes(`${node.id}`)}
                          onChange={() => {
                            if (!values.skillSet.includes(`${node.id}`)) {
                              const newValue = values.skillSet
                              newValue.push(node.id)
                              setFieldValue('skillSet', newValue)
                            } else {
                              const newValue = values.skillSet
                              const index = findIndex(newValue, node.id)
                              if (index !== 'noIndex') {
                                newValue.splice(index, 1)
                              }
                              setFieldValue('skillSet', newValue)
                            }
                          }}
                        />
                        <div className='checkbox-label'>可</div>
                      </div>
                      <div className='checkbox-container checkbox-field'>
                        <Checkbox
                          checked={!values.skillSet.includes(`${node.id}`)}
                          onChange={() => {
                            if (values.skillSet.includes(`${node.id}`)) {
                              const newValue = values.skillSet
                              const index = findIndex(newValue, node.id)
                              if (index !== 'noIndex') {
                                newValue.splice(index, 1)
                              }
                              setFieldValue('skillSet', newValue)
                            } else {
                              const newValue = values.skillSet
                              newValue.push(node.id)
                              setFieldValue('skillSet', newValue)
                            }
                          }}
                        />
                        <div className='checkbox-label'>不可</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='submit-button'>
                <Button
                  type='button'
                  className='back-button p-button-primary'
                  onClick={() => handleBackStep(values)}
                  label={i18n.t('doctor.signup.backLabel')}
                />
                {isSubmitting ? (
                  <ProgressSpinner />
                ) : (
                  <Button
                    type='button'
                    disabled={isSubmitting}
                    className='signup-button p-button-primary'
                    onClick={handleSubmit}
                    label={i18n.t('doctor.signup.submitFinalLabel')}
                  />
                )}
              </div>
            </form>
          </div>
        )
      }}
    />
  )
}

export default SignupComponentStep3
