import './style.css'

import { useMutation, useQuery } from '@apollo/react-hooks'
import BreadCrumb from '@medical/components/Breadcrumb'
import { STAFF_CREATE_REPLY_PERMISSION } from '@medical/constant/permissions'
import { checkStaffPermission } from '@medical/libs'
import InquiryScene from '@medical/pages/doctor/Inquiry/InquiryScene'
import { Popover } from 'antd'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import React, { useState } from 'react'

import {
  DOCTORS_CONNECTION,
  UPDATE_CONVERSATION_STATUS,
} from './Inquiries.graphql'

const InquiriesScene = ({
  i18n,
  popup,
  conversations,
  inquirySender,
  doctorId,
  doctorNameFromParams,
  conversationIdFromParams,
  statusSearch,
  setStatusSearch,
  getMoreConversation,
}) => {
  const [
    selectedConversationsDoctorId,
    setSelectedConversationsDoctorId,
  ] = useState(doctorId)
  const [conversationId, setConversationId] = useState(conversationIdFromParams)
  const [fullName, setFullName] = useState('')
  const [doctorName, setDoctorName] = useState(doctorNameFromParams)
  const [conversationStatus, setConversationStatus] = useState('')
  const [popOverVisible, setPopOverVisible] = useState(false)
  const [staffUpdateConversationStatus] = useMutation(
    UPDATE_CONVERSATION_STATUS
  )
  const isReplyPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_REPLY_PERMISSION,
  })
  const statusOptions = [
    { value: '', description: 'ステータス' },
    { value: 'IN_RESPONSE', description: '対応中' },
    { value: 'COMPLETED', description: '対応済み' },
  ]

  const variables = {
    first: 10,
    skip: 0,
    where: {
      OR: [
        {
          unSigned_contains: fullName
            ? fullName
                .replace(/ /g, '')
                .replace(/　/g, '')
                .toLowerCase()
            : '',
        },
        {
          email_contains: fullName ? fullName.trim() : '',
        },
      ],
    },
  }

  const { data } = useQuery(DOCTORS_CONNECTION, {
    variables,
    fetchPolicy: 'network-only',
  })

  let listDoctor = <div />
  if (
    data &&
    data.doctorsConnection &&
    data.doctorsConnection.edges.length === 0
  )
    listDoctor = (
      <div>
        <p>{i18n.t('staff.inquiries.noDoctor')}</p>
      </div>
    )
  if (data && data.doctorsConnection && data.doctorsConnection.edges.length > 0)
    listDoctor = (
      <div>
        {data.doctorsConnection.edges.map(value => (
          <div
            className='list-doctor-item'
            onClick={e => {
              e.stopPropagation()
              setSelectedConversationsDoctorId(value.node.id)
              setDoctorName(`${value.node.lastname} ${value.node.firstname}`)
              setPopOverVisible(false)
              if (value.node.conversation) {
                setConversationId(value.node.conversation.id)
                setConversationStatus(value.node.conversation.status)
              } else {
                setConversationId('')
                setConversationStatus('')
              }
              const element = document.getElementById(`${value.node.id}`)
              if (element) element.scrollIntoView()
            }}
          >
            {`${value.node.lastname} ${value.node.firstname} (${value.node.email})`}
          </div>
        ))}
      </div>
    )

  const updateConversationStatus = async () => {
    try {
      const result = await staffUpdateConversationStatus({
        variables: {
          conversationId,
          status:
            conversationStatus === 'IN_RESPONSE' ? 'COMPLETED' : 'IN_RESPONSE',
        },
      })
      setConversationStatus(result.data.staffUpsertConversationStatus.status)
      popup.success('編集しました。')
    } catch (error) {
      popup.error(error)
    }
  }

  const trackScrolling = e => {
    const scrollHeight = e.target.scrollHeight - e.target.scrollTop
    if (scrollHeight === e.target.clientHeight) {
      getMoreConversation()
    }
  }
  return (
    <React.Fragment>
      <div className='staff-header'>
        <div className='staff-title'>
          {i18n.t('staff.menuBar.inquiries')}
          <BreadCrumb items={[{ title: i18n.t('staff.menuBar.inquiries') }]} />
        </div>
      </div>
      <div className='container is-max'>
        <div className='box row staff-inquiry'>
          <div className='list-conversations'>
            <div className='search-conversations'>
              <div className='row'>
                <InputText
                  className='inquiry-input'
                  placeholder={i18n.t('staff.inquiries.searchDoctor')}
                  value={fullName}
                  onChange={e => setFullName(e.target.value)}
                />
                <Popover
                  placement='bottomRight'
                  content={listDoctor}
                  trigger='click'
                  overlayClassName='drop-doctor'
                  visible={popOverVisible}
                >
                  <Button
                    icon='pi pi-search'
                    onClick={() => setPopOverVisible(!popOverVisible)}
                  />
                </Popover>
              </div>
              <Dropdown
                style={{ width: '100%' }}
                options={statusOptions}
                value={statusOptions.find(
                  option => option.value === statusSearch
                )}
                optionLabel='description'
                onChange={e => {
                  setStatusSearch(e.target.value.value)
                }}
                // placeholder='ステータス'
              />
            </div>

            <div
              className='conversations-box'
              // ref={chatBoxRef}
              id='listConversationScroll'
              onScroll={trackScrolling}
            >
              {conversations &&
                conversations.length > 0 &&
                conversations.map(value =>
                  value.isReadByStaff ? (
                    <div
                      id={value.doctor.id}
                      className={
                        value.doctor.id === selectedConversationsDoctorId
                          ? 'conversation selected'
                          : 'conversation'
                      }
                      onClick={() => {
                        setPopOverVisible(false)
                        if (value.doctor.id !== selectedConversationsDoctorId) {
                          setSelectedConversationsDoctorId(value.doctor.id)
                          setConversationId(value.id)
                          setConversationStatus(value.status)
                        } else {
                          setSelectedConversationsDoctorId('')
                          setDoctorName('')
                          setConversationId('')
                          setConversationStatus('')
                        }
                      }}
                    >
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span>{`${value.doctor.lastname} ${value.doctor.firstname}`}</span>
                        <span style={{ color: '#f03a6c' }}>
                          {value.status === 'IN_RESPONSE' ? '対応中' : null}
                        </span>
                      </p>
                      <p className='email'>{`(${value.doctor.email})`}</p>
                    </div>
                  ) : (
                    <div
                      id={value.doctor.id}
                      className={
                        value.doctor.id === selectedConversationsDoctorId
                          ? 'conversation selected'
                          : 'conversation'
                      }
                      onClick={() => {
                        setPopOverVisible(false)
                        if (value.doctor.id !== selectedConversationsDoctorId) {
                          setSelectedConversationsDoctorId(value.doctor.id)
                          setConversationId(value.id)
                          setConversationStatus(value.status)
                        } else {
                          setSelectedConversationsDoctorId('')
                          setDoctorName('')
                          setConversationId('')
                          setConversationStatus('')
                        }
                      }}
                    >
                      <p
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span className='unread'>{`${value.doctor.lastname} ${value.doctor.firstname}`}</span>
                        <span style={{ color: '#f03a6c' }}>
                          {value.status === 'IN_RESPONSE' ? '対応中' : null}
                        </span>
                      </p>
                      <p className='email'>{`(${value.doctor.email})`}</p>
                    </div>
                  )
                )}
            </div>
          </div>
          <InquiryScene
            i18n={i18n}
            doctorId={selectedConversationsDoctorId}
            conversationStatus={conversationStatus}
            conversationId={conversationId}
            inquirySender={inquirySender}
            doctorName={doctorName}
            isReplyPermitted={isReplyPermitted}
            setConversationId={e => {
              setConversationId(e)
            }}
            setDoctorName={e => {
              setDoctorName(e)
            }}
            updateConversationStatus={updateConversationStatus}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default InquiriesScene
