import './style.css'

import { useCustom } from '@medical/provider/context'
import {
  addItem,
  removeItem,
} from '@medical/provider/store/reducers/cart/cart.action'
import { Button } from 'primereact/button'
import React, { useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { connect } from 'react-redux'

import CheckShiftWorkLayout from './components/CheckShiftWorkLayout'
import FindJobLayout from './components/FindJob'
import JobSearchBox from './components/JobSearchBox'
import NewsLayout from './components/NewsLayout'
import NewSlider from './components/NewSlider'
import TableDataAvailable from './components/TableDataAvailable'
import MenuLeft from './MenuLeft'
import moment from 'moment'
import Router from '@medical/routes/router'

const renderUnregisterItem = (value, index) => {
  const items = [
    '・氏名',
    '・性別',
    '・生年月日',
    '・診療科目',
    '・電話番号',
    '・郵便番号',
    '・都道府県',
    '・住所1',
    '・現在の主な勤務先',
    '・卒業大学',
    '・卒業年次',
    '・医師免許取得年次',
    '・銀行口座情報',
    '・医師免許証',
    '・保険医登録票',
  ];

  if (!value && index < items.length) {
    return items[index];
  }

  return null;
};

const DoctorHomeScene = ({
  i18n,
  path,
  addItemToCart,
  removeItemFromCart,
  cartItems,
  clinicListJobSearch = [],
  clinicList,
  listWorkPattern,
  isAlert,
  registeredItem,
  history,
  countFirstLogin,
  isDoctorBlocked,
  checkWorkScheduleExits,
  isRequestCert,
}) => {
  const [isShowAlert, setIsAlert] = useState(isAlert && countFirstLogin === 1)
  const [{ popup }] = useCustom()
 
  return (
    <>
      {isAlert && isMobile && (
        <div
          onClick={() => {
            history.push('/doctor/my_page')
          }}
          className='cert-remind-title'
          style={{
            margin: !isMobile && '-15px',
          }}
        >
          <p
            style={{
              display: 'flex',
              cursor: 'pointer',
              textAlign: 'center',
              flexDirection: 'column',
              margin: 0,
            }}
          >
            <span>
              <a
                className='header-link'
                style={{ color: 'white', marginLeft: '5px' }}
                href={Router.doctorMyPage}
              >
                ※保険診療・給与支払に必要な情報を登録してください
              </a>
            </span>
            <span>
              【{' '}
              {registeredItem
                .map((value, index) => {
                  const text = renderUnregisterItem(value, index)
                  if (text) {
                    return text
                  }
                })
                .join('')}{' '}
              】
            </span>
          </p>
        </div>
      )}
      {isRequestCert && (
        <div
          onClick={() => {
            history.push('/doctor/my_page')
          }}
          className='cert-remind-title'
          style={{
            margin: !isMobile ? '-15px' : '-1px',
          }}
        >
          <p
            style={{
              display: 'contents',
              cursor: 'pointer',
            }}
          >
            ※オンライン診療研修の受講 および オンライン診療研修修了証の提出をお願いします。※
          </p>
        </div>
      )}
      <div style={{ padding: '20px 0' }}>
        <div style={{ padding: '10px 0' }}>
          <NewSlider i18n={i18n} />
        </div>
        <div
          className='top-page-container'
          style={isMobile && !isTablet ? { width: '100%' } : null}
        >
          {isMobile && !isTablet ? null : (
            <>
              {path !== '/doctor/' ? null : (
                <>
                  <div className='left-content'>
                    <MenuLeft
                      path={path}
                      clinicList={clinicList}
                      clinicListJobSearch={clinicListJobSearch}
                      listWorkPattern={listWorkPattern}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div
            className='right-content'
            style={isMobile && !isTablet ? { width: '100%' } : null}
          >
            {isMobile && !isTablet && (
              <div className='search-box-content' style={{ margin: '1rem 0' }}>
                {isDoctorBlocked ? null : (
                  <JobSearchBox
                    clinicList={clinicList}
                    listWorkPattern={listWorkPattern}
                    clinicListJobSearch={clinicListJobSearch}
                  />
                )}
              </div>
            )}
            <TableDataAvailable
              cartItems={cartItems}
              addItemToCart={item => {
                if (checkWorkScheduleExits(item)) {
                  popup.error(
                    '同日時で確定シフトがあるためカートに追加できません'
                  )
                } else if (moment(item.startTime).isAfter(moment())) {
                  popup.success('募集シフトをカートに追加しました')
                  addItemToCart(item)
                } else
                  popup.error(
                    'このシフトの開始時間が過ぎましたので応募できません'
                  )
              }}
              removeItemFromCart={item => {
                popup.warn('募集シフトをカートから削除しました')
                removeItemFromCart(item)
              }}
            />
            <CheckShiftWorkLayout />
            {isDoctorBlocked ? null : <FindJobLayout />}
            <NewsLayout />
          </div>
        </div>
      </div>
      {isShowAlert ? (
        <div className='react-router-modal__container'>
          <div className='react-router-modal__wrapper'>
            <div className='react-router-modal__backdrop react-router-modal__backdrop--in' />
            <div
              className='react-router-modal__modal react-router-modal__modal--in'
              role='dialog'
            >
              <div className='modal-container'>
                <div className='modal-content alert-login'>
                  <div
                    className='modal-title'
                    style={
                      isMobile && !isTablet
                        ? {
                            color: '#465564',
                            fontWeight: 'bold',
                            fontSize: '12px',
                          }
                        : { color: '#465564', fontWeight: 'bold' }
                    }
                  >
                    ※保険診療・給与支払に必要な情報を登録してください
                  </div>
                  <div className='modal'>
                    {registeredItem.map((value, index) => {
                      return (
                        <div
                          style={
                            isMobile && !isTablet
                              ? { color: '#db7093', fontSize: '12px' }
                              : { color: '#db7093' }
                          }
                        >
                          {renderUnregisterItem(value, index)}
                        </div>
                      )
                    })}
                  </div>
                  <div className='modal-bottom'>
                    <Button
                      className={
                        isMobile && !isTablet
                          ? 'cancel-button cancel-button-mobile'
                          : 'cancel-button'
                      }
                      label='閉じる'
                      onClick={() => {
                        setIsAlert(false)
                      }}
                    />
                    <Button
                      className={
                        isMobile && !isTablet
                          ? 'reflect-button reflect-button-mobile'
                          : 'reflect-button'
                      }
                      label='登録情報を更新する'
                      onClick={() => {
                        history.push('/doctor/my_page')
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
const mapStateToProps = state => ({
  cartItems: state.cartStore.items,
})
const mapDispatchToProps = dispatch => ({
  addItemToCart: item => dispatch(addItem(item)),
  removeItemFromCart: availableShiftId =>
    dispatch(removeItem(availableShiftId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DoctorHomeScene)
