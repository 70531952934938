import React, { useState } from 'react'
import BreadCrumb from '@medical/components/Breadcrumb'
import './FindAvailableShiftMobile.css'
import { Pagination, Dropdown } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'
import numeralCurrency from '@medical/libs/numeralCurrency'
import { Formik } from 'formik'
import { HOURLY_WAGE_LIST } from '@medical/constant'
import MultipleDatePicker from '../AvailableShift/AvailableShiftComponents/MultipleDatePicker'
import ClinicList from '../AvailableShift/AvailableShiftComponents/ClinicList'
import { useCustom } from '@medical/provider/context'
import SpecifyTime from '../AvailableShift/AvailableShiftComponents/SpecifyTime'
import HourlyWageList from '../AvailableShift/AvailableShiftComponents/HourlyWageList'
import WorkContent from '../AvailableShift/AvailableShiftComponents/WorkContent'
import Router from '@medical/routes/router'
import { onChangePageAnt } from '@medical/libs/onPageChange'
import { Button } from 'primereact/button'
import { ActionItem } from '../AvailableShift/AvailableShiftComponents/ActionItem'
import DialogViewShift from '../DoctorHome/components/DialogViewShift'

const FindAvailableShiftMobile = ({
  tableData,
  dateRange,
  weeks,
  clinics,
  departments,
  startTime,
  endTime,
  morningShift,
  eveningShift,
  nightShift,
  isSpecialHourlyWage,
  hourlyWage,
  clinicList,
  onSubmit,
  PaginationProps,
  addItemToCart,
  removeItemFromCart,
  cartItems,
  chosenType,
  handleChosenType,
  search,
  history,
  countClinics,
  page,
  listRegionData,
}) => {
  const countTime =
    !!startTime.isValid() +
    !!endTime.isValid() +
    morningShift +
    eveningShift +
    nightShift
  const [calendarVisible, setCalendarVisible] = useState(false)
  const [clinicVisible, setClinicVisible] = useState(false)
  const [timeVisible, setTimeVisible] = useState(false)
  const [otherVisible, setOtherVisible] = useState(false)
  const [workContentVisible, setWorkContentVisible] = useState(false)
  const [{ i18n }] = useCustom()
  const [newDateRange, setNewDateRange] = useState(dateRange)
  const [newWeeks, setNewWeeks] = useState(weeks)
  const [selectedShift, setSelectedShift] = useState()

  const handleChangeRange = value => {
    setNewDateRange(value)
  }
  const handleChecked = weeks => {
    setNewWeeks(weeks)
  }
  const clearDate = () => {
    const dateArray = Object.getOwnPropertyNames(newDateRange)
    dateArray.forEach(key => {
      delete newDateRange[key]
    })
  }

  const calendarVisibleChange = flag => {
    setCalendarVisible(flag)
  }
  const clinicVisibleChange = flag => {
    setClinicVisible(flag)
  }
  const timeVisibleChange = flag => {
    setTimeVisible(flag)
  }
  const otherVisibleChange = flag => {
    setOtherVisible(flag)
  }
  const workContentVisibleChange = flag => {
    setWorkContentVisible(flag)
  }
  let countDepartments = departments.length

  if (
    departments.indexOf('CHILDREN_VACCINE_MEDICINE') !== -1 &&
    departments.indexOf('INTERNAL_VACCINE_MEDICINE') !== -1 &&
    departments.length > 0
  ) {
    countDepartments -= 1
  }

  const dataList = []
  tableData.map(item => {
    dataList.push({
      id: item.id,
      startTime: moment(item.startTime)
        .utc()
        .format(),
      endTime: moment(item.endTime)
        .utc()
        .format(),
      clinicalDepartment: item.clinicalDepartment,
      dailySalary: item.dailySalary,
      createdAt: item.createdAt,
      updatedAt: item.updatedAt,
      comment: item.comment,
    })
    return true
  })

  const handleCloseViewShift = () => {
    setSelectedShift(undefined)
  }

  const handleClickAdd = value => {
    if (value.comment) {
      setSelectedShift(value)
    } else {
      addItemToCart(value)
    }
  }

  return (
    <Formik
      initialValues={{
        dateRange: dateRange,
        weeks,
        clinics: clinics,
        startTime: startTime,
        endTime: endTime,
        morningShift: morningShift,
        eveningShift: eveningShift,
        nightShift: nightShift,
        isSpecialHourlyWage: isSpecialHourlyWage,
        hourlyWage: hourlyWage,
        hourlyWageList: HOURLY_WAGE_LIST,
        departments,
      }}
      onSubmit={onSubmit}
      render={formikProps => {
        const { setFieldValue, handleSubmit } = formikProps
        return (
          <div className='mobile-container'>
            <div className='mobible-inner'>
              <div className='staff-title'>
                {i18n.t('doctor.menuBar.availableShifts')}
                <BreadCrumb
                  items={[
                    {
                      to: Router.get(
                        Router.doctorAvailableShiftsWithoutYearMonth
                      ),
                      title: 'シフトを探す',
                    },
                    { title: i18n.t('main.availableShiftList') },
                  ]}
                />
              </div>
              <div className='search-mobile_wrapper'>
                {/* クリニック */}
                <Dropdown
                  trigger={['click']}
                  visible={calendarVisible}
                  onVisibleChange={calendarVisibleChange}
                  overlayClassName='main-calender'
                  overlay={
                    <div className='dropdown-search-feature-mobile calendar-open'>
                      <MultipleDatePicker
                        style={{ width: '300px' }}
                        {...formikProps}
                        field='dateRange'
                        locale='ja'
                        ranges={newDateRange}
                        handleChangeRange={handleChangeRange}
                        months={1}
                        handleChecked={handleChecked}
                        weeks={newWeeks}
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            moment()
                              .add(12, 'months')
                              .calendar()
                          )
                        }
                      />

                      <div className='submit-search-wapper-mobile'>
                        <button
                          className='clear-search-btn-mobile'
                          onClick={async () => {
                            await setCalendarVisible(false)
                            await setFieldValue('dateRange', {})
                            await clearDate()
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>

                        <button
                          className='submit-search-btn-mobile'
                          type='submit'
                          onClick={async () => {
                            await handleSubmit()

                            await setCalendarVisible(false)
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <button
                    className='search-item-btn-mobile calendar-btn'
                    type='link'
                  >
                    期間
                    {Object.keys(newDateRange).length > 0 ? (
                      <span className='p-search-selected'>
                        {Object.keys(newDateRange).length}
                      </span>
                    ) : null}
                    <DownOutlined
                      style={{ color: 'var(--primary)', marginLeft: '0' }}
                    />
                  </button>
                </Dropdown>

                <Dropdown
                  trigger={['click']}
                  visible={clinicVisible}
                  onVisibleChange={clinicVisibleChange}
                  overlayClassName='main-calender'
                  overlay={
                    <div className='dropdown-search-feature-mobile clinic-open'>
                      <ClinicList
                        {...formikProps}
                        clinics={clinicList}
                        listRegionData={listRegionData}
                        isMobile
                      />
                      <div className='submit-search-wapper-mobile'>
                        <button
                          className='clear-search-btn-mobile'
                          onClick={async () => {
                            setClinicVisible(false)
                            await setFieldValue('clinics', [])
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>

                        <button
                          onClick={() => {
                            setClinicVisible()
                            handleSubmit()
                          }}
                          className='submit-search-btn-mobile'
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <button
                    className='search-item-btn-mobile clinic-btn'
                    type='link'
                  >
                    クリニック
                    {countClinics > 0 ? (
                      <span className='p-search-selected'>{countClinics}</span>
                    ) : null}
                    <DownOutlined
                      style={{ color: 'var(--primary)', marginLeft: '0px' }}
                    />
                  </button>
                </Dropdown>
                <Dropdown
                  trigger={['click']}
                  visible={workContentVisible}
                  onVisibleChange={workContentVisibleChange}
                  overlay={
                    <div className='dropdown-search-feature other-open'>
                      <WorkContent {...formikProps} i18n={i18n} />
                      <div className='space-dropdown'></div>
                      <div className='submit-search-wapper'>
                        <button
                          className='clear-search-btn'
                          onClick={async () => {
                            setWorkContentVisible(false)
                            await setFieldValue('departments', [])
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>
                        <button
                          type='submit'
                          className='submit-search-btn'
                          onClick={() => {
                            setWorkContentVisible(false)
                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <button
                    className='search-item-btn-mobile other-btn'
                    type='link'
                  >
                    勤務内容を指定
                    {countDepartments > 0 ? (
                      <span className='p-search-selected'>
                        {countDepartments}
                      </span>
                    ) : null}
                    <DownOutlined style={{ color: 'var(--primary)' }} />
                  </button>
                </Dropdown>
                <Dropdown
                  trigger={['click']}
                  visible={timeVisible}
                  onVisibleChange={timeVisibleChange}
                  overlayClassName='main-calender'
                  overlay={
                    <div className='dropdown-search-feature-mobile time-open'>
                      <SpecifyTime
                        {...formikProps}
                        i18n={i18n}
                        chosenType={chosenType}
                        handleChosenType={handleChosenType}
                      />
                      <div className='submit-search-wapper-mobile'>
                        <button
                          className='clear-search-btn-mobile'
                          onClick={async () => {
                            setTimeVisible(false)
                            await setFieldValue('morningShift', false)
                            await setFieldValue('eveningShift', false)
                            await setFieldValue('nightShift', false)
                            await setFieldValue('startTime', null)
                            await setFieldValue('endTime', null)
                            await handleSubmit()
                          }}
                        >
                          クリア
                        </button>

                        <button
                          className='submit-search-btn-mobile'
                          onClick={() => {
                            setTimeVisible(false)

                            handleSubmit()
                          }}
                        >
                          適用する
                        </button>
                      </div>
                    </div>
                  }
                >
                  <button
                    className='search-item-btn-mobile time-btn'
                    type='link'
                  >
                    勤務時間
                    {countTime > 0 ? (
                      <span className='p-search-selected'>{countTime}</span>
                    ) : null}
                    <DownOutlined
                      style={{ color: 'var(--primary)', marginLeft: '0px' }}
                    />
                  </button>
                </Dropdown>

                <Dropdown
                  trigger={['click']}
                  visible={otherVisible}
                  onVisibleChange={otherVisibleChange}
                  overlayClassName='main-calender'
                  overlay={
                    <div className='dropdown-search-feature-mobile other-open'>
                      <div>
                        {' '}
                        <HourlyWageList
                          {...formikProps}
                          i18n={i18n}
                          hourlyWageList={HOURLY_WAGE_LIST}
                          placeholder={i18n.t(
                            'doctor.availableShifts.undefinedHourlyWage'
                          )}
                        />
                        <div className='submit-search-wapper-mobile'>
                          <button
                            className='clear-search-btn-mobile'
                            onClick={async () => {
                              await setFieldValue('hourlyWage', null)
                              await setFieldValue('isSpecialHourlyWage', null)
                              await handleSubmit()
                            }}
                          >
                            クリア
                          </button>

                          <button
                            className='submit-search-btn-mobile'
                            onClick={() => {
                              setOtherVisible(false)

                              handleSubmit()
                            }}
                          >
                            適用する
                          </button>
                        </div>
                      </div>
                    </div>
                  }
                >
                  <button
                    className='search-item-btn-mobile other-btn'
                    type='link'
                  >
                    条件
                    <DownOutlined
                      style={{ color: 'var(--primary)', marginLeft: '0px' }}
                    />
                  </button>
                </Dropdown>
              </div>
            </div>
            <div className='mobile-note-wrapper'>
              <div className='mobile-note'>
                <span
                  className='mobile-note-sp'
                  style={{ padding: '0px 16px' }}
                >
                  New
                </span>

                <span> 前回ログインから新たに募集開始したシフト</span>
              </div>
              <div className='mobile-note'>
                <span
                  className='mobile-note-sp'
                  style={{ backgroundColor: 'var(--yellow)' }}
                >
                  Update
                </span>
                <span> 前回ログインから新たに募集開始したシフト</span>
              </div>
            </div>
            <div className='table-Mobile'>
              {dataList.map((value, index) => (
                <tr className='table-mobile-row'>
                  <td className='table-mobile-td'>
                    {moment(value.startTime).format('YYYY/MM/DD')}
                    <span style={{ marginLeft: '3px' }}>
                      {moment(value.startTime).format('HH:mm')}~
                      {moment(value.endTime).format('HH:mm')}
                    </span>
                  </td>
                  <td className='table-mobile-td' style={{ margin: '0px 5px' }}>
                    <div
                      className='available-shift-list row'
                      style={{ justifyContent: 'space-evenly' }}
                    >
                      {value.updatedAt === value.createdAt ? (
                        <p className='tag-name'>New</p>
                      ) : (
                        <p className='tag-name update'>Update</p>
                      )}
                    </div>
                    <span>{value.clinicalDepartment.clinic.name}</span>
                  </td>
                  <td
                    className='table-mobile-td'
                    style={{ flexDirection: 'column' }}
                  >
                    {i18n.t(`main.${value.clinicalDepartment.name}`)}
                    <p style={{ padding: '0' }}>
                      {numeralCurrency(value.dailySalary)}
                    </p>
                  </td>
                  <td className='table-mobile-td'>
                    <ActionItem item={value} type='availableShifts' />
                  </td>

                  <td className='table-mobile-td'>
                    <div className='table-action'>
                      {cartItems.some(element => element.id === value.id) ? (
                        <Button
                          onClick={() => removeItemFromCart(value.id)}
                          icon='pi pi-times'
                          className='p-button-danger'
                        />
                      ) : (
                        <Button
                          onClick={() => {
                            handleClickAdd(value)
                          }}
                          icon='pi pi-plus'
                          className='p-button-success'
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </div>

            <Pagination
              id='test'
              total={PaginationProps.count}
              size='small'
              pageSize={PaginationProps.pageSize}
              current={parseInt(page)}
              onChange={onChangePageAnt(history, search)}
              style={{ textAlign: 'center', margin: '30px 0px' }}
              showSizeChanger={false}
            />

            {!!selectedShift && (
              <DialogViewShift
                open={!!selectedShift}
                onClose={handleCloseViewShift}
                item={selectedShift}
                addItemToCart={addItemToCart}
                isAddToCart
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default FindAvailableShiftMobile
