import './style.css'

import Chips from '@medical/components/ChipsSignup'
import { phoneRegExp } from '@medical/constant'
import { getDetailAddressFromZipCode } from '@medical/libs/getAddressFromZipcode'
import { ErrorMessage, Formik } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  phoneFirstRegExp,
  phoneSecondRegExp,
  phoneThirdRegExp,
  PROVINCES,
} from '@medical/constant'
import * as Yup from 'yup'
import AutoComplete from '@medical/components/AutoComplete'

const SignupComponentStep2 = ({
  handleBackStep,
  handleNextStep,
  stateData,
}) => {
  const { i18n } = useTranslation()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        zipCodeFirst: stateData.zipCodeFirst,
        zipCodeSecond: stateData.zipCodeSecond,
        phoneNumberFirst: stateData.phoneNumberFirst,
        phoneNumberSecond: stateData.phoneNumberSecond,
        phoneNumberThird: stateData.phoneNumberThird,
        // phoneNumber: `${phoneNumberFirst}${phoneNumberSecond}${phoneNumberThird}`,
        // zipCode: `${zipCodeFirst}${zipCodeSecond}`,
        stateOrRegion: stateData.stateOrRegion,
        address1: stateData.address1,
        address2: stateData.address2,
        nearestStations: stateData.nearestStations,
        placeOfWork: stateData.placeOfWork,
        province: stateData.province,
      }}
      validationSchema={Yup.object().shape({
        // phoneNumber: Yup.string()
        //   .matches(phoneRegExp, i18n.t('main.phoneNumberNotValid'))
        //   .required(
        //     `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
        //       'validation.required'
        //     )}`
        //   ),
        placeOfWork: Yup.string().required(
          `${i18n.t('updateProfile.placeOfWork')}${i18n.t(
            'validation.required'
          )}`
        ),
        phoneNumberFirst: Yup.string()
          .matches(phoneFirstRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        phoneNumberSecond: Yup.string()
          .matches(phoneSecondRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        phoneNumberThird: Yup.string()
          .matches(phoneThirdRegExp, i18n.t('main.phoneNumberNotValid'))
          .required(
            `${i18n.t('updateProfile.phoneNumber')}${i18n.t(
              'validation.required'
            )}`
          ),
        province: Yup.string()
          .nullable()
          .test('check exits', '都道府県のみ入力必須', val =>
            val
              ? PROVINCES.filter(({ name_ja }) => name_ja === val).length > 0
              : true
          ),
        zipCode: Yup.string().nullable(),
        stateOrRegion: Yup.string()
          .nullable()
          .test(
            'check exits',
            `${i18n.t('updateProfile.homeAddress.stateOrRegion')}${i18n.t(
              'validation.required'
            )}`,
            val =>
              val
                ? PROVINCES.filter(({ name_ja }) => name_ja === val).length > 0
                : true
          ),
        address1: Yup.string().nullable(),
        address2: Yup.string().nullable(),
      })}
      onSubmit={values => {
        handleNextStep(values)
      }}
      render={formikProps => {
        const {
          values,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
        } = formikProps
        const getHomeAddress = async zipCode => {
          const {
            status,
            administrativeArea,
            localityArea,
          } = await getDetailAddressFromZipCode({
            zipCode: zipCode,
          })
          if (status === 'OK') {
            setFieldValue('stateOrRegion', administrativeArea)
            setFieldValue('address1', localityArea)
          }
        }
        return (
          <div>
            <form>
              <div className='signup-inner'>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.phoneNumberLabel')}
                </div>
                <div className='signup-input'>
                  <div className='input-split-wrap'>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component '
                        type='text'
                        name='phoneNumberFirst'
                        defaultValue={values.phoneNumberFirst}
                        pattern='[0-9]{3}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='3'
                      />
                      <ErrorMessage
                        name='phoneNumberFirst'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                    <span>-</span>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='phoneNumberSecond'
                        defaultValue={values.phoneNumberSecond}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='phoneNumberSecond'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                    <span>-</span>
                    <div className='input-split'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='phoneNumberThird'
                        defaultValue={values.phoneNumberThird}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='phoneNumberThird'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                  {/* <input
                    className='p-inputtext p-component'
                    type='text'
                    value={values.phoneNumber}
                    values={values}
                    name='phoneNumber'
                    pattern='[0-9-]*'
                    onChange={e => {
                      e.target.value = e.target.value.replace(/[^0-9-]/g, '')
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name='phoneNumber'
                    component='div'
                    className='signup-alert'
                  /> */}
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <div className='signup-label'>
                      {i18n.t('doctor.signup.postalCodeLabel')}
                    </div>
                    <div className=' signup-input-half input-split-wrap'>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='zipCodeFirst'
                        defaultValue={values.zipCodeFirst}
                        pattern='[0-9]{3}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.zipCodeSecond &&
                            e.target.value.length === 3
                          ) {
                            getHomeAddress(
                              `${e.target.value}${values.zipCodeSecond}`
                            )
                          }
                        }}
                        maxLength='3'
                      />
                      <ErrorMessage
                        name='zipCodeFirst'
                        component='div'
                        className='signup-alert'
                      />
                      <span>-</span>
                      <InputText
                        className='p-inputtext p-component'
                        type='text'
                        name='zipCodeSecond'
                        defaultValue={values.zipCodeSecond}
                        pattern='[0-9]{4}'
                        onChange={e => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '')
                          handleChange(e)
                          if (
                            values.zipCodeFirst &&
                            e.target.value.length === 4
                          ) {
                            getHomeAddress(
                              `${values.zipCodeFirst}${e.target.value}`
                            )
                          }
                        }}
                        maxLength='4'
                      />
                      <ErrorMessage
                        name='homeAddress.zipCodeSecond'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='signup-label'>
                      {i18n.t('doctor.signup.districtLabel')}
                    </div>
                    <div className='signup-input-half '>
                      <AutoComplete
                        {...formikProps}
                        name='stateOrRegion'
                        field='name_ja'
                        allSuggestions={PROVINCES}
                        suggestionName='provinces'
                        placeholder='都道府県を選択'
                        setCurrentProvince={val => {
                          const data = PROVINCES.find(
                            ({ name_ja }) => name_ja === val
                          )

                          setFieldValue('stateOrRegion', data?.name_ja)
                        }}
                      />
                      <ErrorMessage
                        name='stateOrRegion'
                        component='div'
                        className='signup-alert'
                      />
                    </div>
                  </div>
                </div>

                <div className='signup-label'>
                  {i18n.t('doctor.signup.address1Label')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.address1}
                    name='address1'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='address1'
                    component='div'
                    className='signup-alert'
                  />
                </div>

                <div className='signup-label'>
                  {i18n.t('doctor.signup.address2Label')}
                </div>
                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.address2}
                    name='address2'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='address2'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('updateProfile.province')}
                </div>

                <div className='signup-input'>
                  <AutoComplete
                    {...formikProps}
                    name='province'
                    field='name_ja'
                    allSuggestions={PROVINCES}
                    suggestionName='provinces'
                    placeholder='都道府県を選択'
                    width='50vw'
                    maxWidth='386px'
                    setCurrentProvince={val => {
                      const data = PROVINCES.find(
                        ({ name_ja }) => name_ja === val
                      )

                      setFieldValue('province', data?.name_ja)
                    }}
                  />
                  <ErrorMessage
                    name='province'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label required-label'>
                  {i18n.t('doctor.signup.placeOfWorkLabel')}
                </div>

                <div className='signup-input'>
                  <InputText
                    values={values}
                    value={values.placeOfWork}
                    name='placeOfWork'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoCapitalize='none'
                  />
                  <ErrorMessage
                    name='placeOfWork'
                    component='div'
                    className='signup-alert'
                  />
                </div>
                <div className='signup-label'>
                  {i18n.t('doctor.signup.nearestStationLabel')}
                </div>
                <div className='signup-input'>
                  <div className='chips-input'>
                    <Chips {...formikProps} name='nearestStations' />
                  </div>

                  <ErrorMessage
                    name='nearestStations'
                    component='div'
                    className='signup-alert'
                  />
                </div>
              </div>
              <div className='submit-button'>
                <Button
                  type='button'
                  className='back-button p-button-primary'
                  onClick={() => handleBackStep(values)}
                  label={i18n.t('doctor.signup.backLabel')}
                />
                <Button
                  type='button'
                  className='signup-button p-button-primary'
                  onClick={handleSubmit}
                  label={i18n.t('doctor.signup.submitLabel')}
                />
              </div>
            </form>
          </div>
        )
      }}
    />
  )
}

export default SignupComponentStep2
