import './MenuLeft.css'

import { FileTextOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { ReactComponent as Clinic } from '@medical/assets/image/clinic.svg'
import { ReactComponent as Question } from '@medical/assets/image/question.svg'
import { ReactComponent as Portal } from '@medical/assets/image/stack-fill.svg'
import { ReactComponent as Tranport } from '@medical/assets/image/subway-line.svg'
import { CONVERSATIONS } from '@medical/layout/DoctorLayout//DoctorLayoutRequest.graphql'
import getMenuPath from '@medical/libs/getMenuPath'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import { Accordion, AccordionTab } from 'primereact/accordion'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { parseJwt } from '../../../constant/utilities'
import JobSearchBox from './components/JobSearchBox'

const MenuLeft = ({
  path,
  clinicList,
  listWorkPattern,
  clinicListJobSearch,
}) => {
  const [
    { i18n, activeIndexSecond, isDoctorBlocked },
    { setActiveIndexSecond },
  ] = useCustom()
  const { doctorId } = parseJwt(localStorage.getItem('medical_doctor_token'))
  const { data: conversationData } = useQuery(CONVERSATIONS, {
    variables: {
      where: {
        Doctor: {
          id: doctorId,
        },
      },
    },
  })
  let newMsg = false
  const [{ year, month }] = useState({
    year: moment().year(),
    month: moment().month() + 1,
  })
  if (conversationData.conversations.length > 0) {
    newMsg = conversationData.conversations[0].isReadByDoctor === false
  }
  return (
    <>
      <div className='menu-left-inner'>
        {isDoctorBlocked ? null : (
          <JobSearchBox
            clinicList={clinicList}
            clinicListJobSearch={clinicListJobSearch}
            listWorkPattern={listWorkPattern}
          />
        )}
        <Link className='menu-left-link' to={Router.doctorAvailableShifts}>
          <i
            className='pi pi-calendar'
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          {i18n.t('doctor.menuBar.availableShifts')}
        </Link>
        {isDoctorBlocked ? null : (
          <Link className='menu-left-link' to={Router.doctorRecruitments}>
            <i
              className='pi pi-calendar'
              style={{ fontSize: '20px', marginRight: '5px' }}
            />
            採用情報を見る
          </Link>
        )}
        <Link
          className='menu-left-link'
          to={Router.doctorRequestedWorkSchedulesListWithoutYearMonth}
        >
          <i
            className='pi pi-list'
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          応募中シフト一覧
        </Link>
        <Link
          className='menu-left-link'
          to={Router.doctorAcceptedWorkSchedulesListWithoutYearMonth}
        >
          <i
            className='pi pi-list'
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          確定シフト一覧
        </Link>
        <Link
          className='menu-left-link'
          to={Router.doctorWorkReportsWithoutYearMonth}
        >
          <i
            className='pi pi-list'
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          勤務実績
        </Link>
        <Accordion
          activeIndex={activeIndexSecond}
          onTabClose={() => setActiveIndexSecond(null)}
          onTabOpen={() => setActiveIndexSecond(0)}
        >
          <AccordionTab
            header={
              <>
                <Tranport
                  style={{
                    fontSize: '20px',
                    color: 'var(--primary)',
                  }}
                />
                <span
                  style={{
                    marginLeft: '5px',
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                >
                  {i18n.t('doctor.menuBar.transportApplication')}
                </span>
              </>
            }
          >
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorTransportReportsWithoutYearMonth,
                path
              )}`}
              to={Router.doctorTransportReportsWithoutYearMonth}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                交通費申請
              </p>
            </Link>
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorTransportRegister,
                path
              )}`}
              to={Router.doctorTransportRegister}
            >
              <p
                style={{
                  paddingLeft: '20px',
                  fontWeight: '400',
                }}
              >
                {i18n.t('doctor.menuBar.transportFeeRegistration')}
              </p>
            </Link>
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorTransportPeriodic,
                path
              )}`}
              to={Router.doctorTransportPeriodic}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                その他交通費精算
              </p>
            </Link>
          </AccordionTab>
        </Accordion>
        <Accordion
          activeIndex={activeIndexSecond}
          onTabClose={() => setActiveIndexSecond(null)}
          onTabOpen={() => setActiveIndexSecond(0)}
          className='doctornav-group'
        >
          <AccordionTab
            header={
              <>
                <FileTextOutlined
                  style={{
                    fontSize: '20px',
                    color: 'var(--primary)',
                  }}
                />{' '}
                <span
                  style={{
                    marginLeft: '5px',
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                >
                  各種申請・登録
                </span>
              </>
            }
          >
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorWaitingRecruitment,
                path
              )}`}
              to={Router.doctorWaitingRecruitment}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                募集待ち登録
              </p>
            </Link>
            {/* <a
              className={`menu-left-link ${getMenuPath(
                'https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform',
                path
              )}`}
              href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                時間外勤務申請
              </p>
            </a> */}
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                path
              )}`}
              to={Router.get(
                Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                {
                  year,
                  month,
                }
              )}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                時間外勤務申請
              </p>
            </Link>
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorAcceptedWorkSchedulesListWithoutYearMonth,
                path
              )}`}
              to={Router.doctorAcceptedWorkSchedulesListWithoutYearMonth}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                シフトキャンセル申請
              </p>
            </Link>
            <Link
              className={`menu-left-link ${getMenuPath(
                Router.doctorWorkOutsideClinic,
                path
              )}`}
              to={Router.doctorWorkOutsideClinic}
            >
              <p style={{ paddingLeft: '20px', fontWeight: '400' }}>
                拠点用シフト申請
              </p>
            </Link>
          </AccordionTab>
        </Accordion>
        <Link to={Router.doctorClinic} className='menu-left-link'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <i
              style={{ marginLeft: '2px', marginBottom: '-3px' }}
              className='bg-red mt-0'
            >
              <Clinic />
            </i>
            <span style={{ marginLeft: '5px' }}>
              {i18n.t('doctor.menuBar.clinicList')}
            </span>
          </div>
        </Link>

        <Link to={Router.doctorNews} className='menu-left-link'>
          <i
            className='pi pi-home'
            style={{ fontSize: '20px', marginRight: '5px' }}
          />
          {i18n.t('doctor.menuBar.news')}
        </Link>
        <Link to={Router.doctorInquiry} className='menu-left-link'>
          <i
            className='pi pi-briefcase'
            style={{ fontSize: '25px', marginRight: '5px' }}
          />
          {i18n.t('doctor.menuBar.inquiry')}
          {newMsg ? (
            <div className='newMsg'>
              <span className='newMsg-text'>New</span>
            </div>
          ) : null}
        </Link>
        <Link to={Router.doctorPortal} className='menu-left-link'>
          <Portal />
          <span style={{ marginLeft: '7px' }}>
            {i18n.t('doctor.menuBar.portal')}
          </span>
        </Link>

        <Link to={Router.doctorQuestions} className='menu-left-link'>
          <Question style={{ marginRight: '5px' }} />
          {i18n.t('doctor.menuBar.question')}
        </Link>
        <a
          href='https://3a10b494-3a9b-4d5e-8b25-c1a27473161b.usrfiles.com/ugd/3a10b4_3904dbe747cf4fda9459824a3b3be579.pdf'
          target='blank'
          rel='noopener noreferrer'
          className='menu-left-link'
        >
          <i
            className='pi pi-external-link'
            style={{ fontSize: '20px', marginRight: '5px', fontWeight: '600' }}
          />
          {i18n.t('doctor.menuBar.useSite')}
        </a>
        <a
          href='https://docs.google.com/forms/d/e/1FAIpQLScbgycZiyKrbL8I5UJ_wa6PK5GBQPTTnxUvozIz-zOCjipnXw/viewform'
          target='blank'
          rel='noopener noreferrer'
          className='menu-left-link'
        >
          <i
            className='pi pi-external-link'
            style={{ fontSize: '20px', marginRight: '5px', fontWeight: '600' }}
          />
          {i18n.t('doctor.menuBar.overtimeApplication')}
        </a>
        <a
          href='https://sites.google.com/view/caps365doctors/access'
          target='blank'
          rel='noopener noreferrer'
          className='menu-left-link'
        >
          <i
            className='pi pi-external-link'
            style={{ fontSize: '20px', marginRight: '5px', fontWeight: '600' }}
          />
          {i18n.t('doctor.menuBar.accessInformation')}
        </a>
      </div>
    </>
  )
}

export default MenuLeft
