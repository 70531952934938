/* eslint-disable react-hooks/exhaustive-deps */
import { MAX_HOURLY_WAGE } from '@medical/constant'
import {
  calculaterSubsidy,
  calculateSalary,
  getAvailableTime,
  splitLogic,
} from '@medical/libs'
import { Checkbox } from 'antd'
import { FieldArray } from 'formik'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Spinner } from 'primereact/spinner'
import { pathOr } from 'ramda'
import React, { useEffect, useState } from 'react'

import AutoComplete from './ShiftFormSplitsAutoComplete'
import styles from './Style'

const ShiftFormSplits = ({
  i18n,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  disabled,
  isMoneyPermitted,
  isCreateAvailableShift,
  isDetailAvailableShift,
  getStartTimeValue,
  getEndTimeValue,
  doctorHourlyWageRange,
  currentHourlyWage,
  handleClickAddHourlyWage,
  handleDeleteHourlyWage,
  availableShift,
  isCreateWorkSchedule,
  isRecruitmentGroup,
  isHideWarningText,
}) => {
  const [count, setCount] = useState(0)
  const checkShowActualTime = wsStartTime => {
    if (moment().isAfter(moment(wsStartTime).endOf('day'))) return true
    if (
      moment().format('YYYY-MM-DD') ===
        moment(wsStartTime).format('YYYY-MM-DD') &&
      moment().isAfter(
        moment()
          .startOf('day')
          .add(21, 'hours')
          .format('YYYY-MM-DD HH:mm:ss')
      )
    )
      return true
    return false
  }

  const hourlyWageRange = doctorHourlyWageRange || 0
  useEffect(() => {
    if (
      ((isCreateAvailableShift && availableShift && !availableShift.splits) ||
        (isCreateWorkSchedule && availableShift && !availableShift.splits)) &&
      count === 0
    ) {
      for (let i = 0; i < values.splits.length; i += 1) {
        setFieldValue(`splits[${i}].hourlyWage`, currentHourlyWage[i])
      }
    }
  }, [currentHourlyWage])

  // useEffect(() => {
  //   if (workSchedule && !isCreateAvailableShift) {
  //     values.splits.splice(1, values.splits.length)
  //     setFieldValue(
  //       `splits.${0}.hourlyWage`,
  //       values.doctor && values.doctor.hourlyWage ? values.doctor.hourlyWage : 0
  //     )
  //   }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values.doctor])

  return (
    <React.Fragment>
      <div style={styles.row}>
        <div className='shiftForm-container'>
          <FieldArray
            name='splits'
            render={({ remove, push }) => (
              <React.Fragment>
                <table
                  style={{
                    width: '100%',
                    margin: '10px',
                  }}
                >
                  <thead>
                    <tr>
                      <th style={styles.th} width='32%'>
                        {i18n.t('staff.createShift.startTime')}
                      </th>
                      <th style={styles.th} width='32%'>
                        {i18n.t('staff.createShift.endTime')}
                      </th>
                      {isMoneyPermitted && (
                        <th style={styles.th} width='36%'>
                          <span style={styles.input}>
                            {i18n.t('staff.createShift.hourlyWage')}
                          </span>
                        </th>
                      )}
                      <th style={{ paddingLeft: '10px' }}>休憩時間</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.splits.map((split, index) => {
                      const prevDate = index
                        ? moment(values.splits[index - 1].date)
                        : moment(values.startTime)
                      const minDate = prevDate.format()
                      return (
                        <tr key={index}>
                          <td>
                            {index ? (
                              <>
                                <AutoComplete
                                  value={prevDate.toDate()}
                                  disabled
                                />
                              </>
                            ) : (
                              <>
                                <AutoComplete
                                  value={values.startTime}
                                  onChange={e => {
                                    setFieldValue('startTime', e.value, false)
                                    const startTime = moment(e.value)

                                    if (getStartTimeValue) {
                                      getStartTimeValue(e.value, index)
                                    }
                                    values.splits.forEach((s, i) => {
                                      if (
                                        startTime.isSameOrAfter(moment(s.date))
                                      ) {
                                        const date = getAvailableTime({
                                          currentTime: e.value,
                                        })
                                        setFieldValue(
                                          `splits.${i}.date`,
                                          date,
                                          false
                                        )
                                      }
                                    })
                                  }}
                                  disabled={disabled}
                                />
                              </>
                            )}
                          </td>
                          <td>
                            <>
                              <AutoComplete
                                value={split.date}
                                onChange={e => {
                                  setFieldValue(
                                    `splits[${index}].date`,
                                    e.value,
                                    false
                                  )
                                  const startTime = moment(e.value)
                                  if (
                                    getEndTimeValue &&
                                    index === values.splits.length - 1
                                  ) {
                                    getEndTimeValue(e.value, index)
                                  } else if (getEndTimeValue) {
                                    getEndTimeValue(
                                      values.splits[values.splits.length - 1]
                                        .date,
                                      index
                                    )
                                  }
                                  for (
                                    let i = index + 1;
                                    i < values.splits.length;
                                    i += 1
                                  ) {
                                    if (
                                      startTime.isSameOrAfter(
                                        moment(values.splits[i].date)
                                      )
                                    ) {
                                      const date = getAvailableTime({
                                        currentTime: e.value,
                                      })
                                      if (
                                        getEndTimeValue &&
                                        index !== values.splits.length - 1
                                      ) {
                                        getEndTimeValue(date, index)
                                      }
                                      setFieldValue(
                                        `splits[${i}].date`,
                                        date,
                                        false
                                      )
                                    }
                                  }
                                }}
                                minDate={minDate}
                                disabled={disabled}
                              />
                            </>
                          </td>
                          {isMoneyPermitted && (
                            <td
                              style={{ display: 'flex', flexDirection: 'row' }}
                            >
                              <Spinner
                                onChange={e => {
                                  handleChange(e)
                                  setCount(1)
                                }}
                                value={values.splits[index].hourlyWage}
                                name={`splits[${index}].hourlyWage`}
                                onBlur={() => {
                                  // eslint-disable-next-line no-restricted-globals
                                  if (isNaN(values.splits[index].hourlyWage)) {
                                    setFieldValue(
                                      `splits.${index}.hourlyWage`,
                                      0
                                    )
                                  }
                                }}
                                style={styles.input}
                                min={0}
                                max={MAX_HOURLY_WAGE}
                                step={500}
                                formatInput
                                className='split-autocomplete'
                                disabled={
                                  disabled || values.splits[index].isBreakTime
                                }
                              />
                              {!!index && (
                                <Button
                                  onClick={() => {
                                    remove(index)
                                    if (
                                      index === values.splits.length - 1 &&
                                      getEndTimeValue
                                    ) {
                                      getEndTimeValue(
                                        values.splits[values.splits.length - 2]
                                          .date
                                      )
                                    } else if (getEndTimeValue) {
                                      getEndTimeValue(
                                        values.splits[values.splits.length - 1]
                                          .date
                                      )
                                    }
                                    if (handleDeleteHourlyWage) {
                                      handleDeleteHourlyWage(index)
                                    }
                                  }}
                                  icon='pi pi-times'
                                  className='p-button-danger'
                                  style={{
                                    display: 'flex',
                                    marginLeft: '10px',
                                  }}
                                  disabled={disabled}
                                />
                              )}
                              <div className='alert'>
                                {pathOr(
                                  null,
                                  ['splits', index, 'hourlyWage'],
                                  touched
                                ) &&
                                  pathOr(
                                    null,
                                    ['splits', index, 'hourlyWage'],
                                    errors
                                  )}
                              </div>
                            </td>
                          )}
                          <td style={{ paddingLeft: '10px' }}>
                            <Checkbox
                              checked={values.splits[index].isBreakTime}
                              name={`splits[${index}].isBreakTime`}
                              onChange={e => {
                                setFieldValue(
                                  `splits.${index}.isBreakTime`,
                                  e.target.checked
                                )
                                if (e.target.checked)
                                  setFieldValue(`splits.${index}.hourlyWage`, 0)
                              }}
                              disabled={disabled}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                  <div
                    style={{
                      width: 'auto',
                    }}
                  ></div>
                </table>
                <div
                  style={{
                    color: 'red',
                  }}
                >
                  {isHideWarningText
                    ? '＊午前午後夜間の時間帯を跨ぐシフトは、募集シフト自動時給変更機能でシフト開始時間の時給へ上書きされます'
                    : null}
                </div>

                <div style={{ textAlign: 'right' }}>
                  {!disabled && values.splits.length < 4 && (
                    <Button
                      onClick={() => {
                        const newDate = getAvailableTime({
                          currentTime:
                            values.splits[values.splits.length - 1]?.date,
                        })
                        if (getEndTimeValue) {
                          getEndTimeValue(newDate)
                        }
                        if (
                          (isCreateAvailableShift || isCreateWorkSchedule) &&
                          handleClickAddHourlyWage
                        ) {
                          handleClickAddHourlyWage(
                            values.splits[values.splits.length - 1]?.date,
                            values.splits.length,
                            values.splits
                          )
                        }
                        push({
                          date: newDate,
                          hourlyWage: 0,
                          isBreakTime: false,
                        })
                      }}
                      icon='pi pi-plus'
                      label={i18n.t('main.add')}
                    />
                  )}
                </div>
              </React.Fragment>
            )}
          />
        </div>
      </div>
      {isCreateAvailableShift || isDetailAvailableShift ? null : (
        <>
          {values.actualTime && checkShowActualTime(values.startTime) ? (
            <div className='tr'>
              <div className='th'>元のシフト時間</div>
              <div className='td'>
                {values.actualTime.map(item => (
                  <div>{item}</div>
                ))}
              </div>
            </div>
          ) : null}
        </>
      )}
      {isMoneyPermitted && (
        <div className='tr'>
          <div className='th'>{i18n.t('main.dailySalary')}</div>
          <div className='td'>
            {calculateSalary({
              availableShift: splitLogic({
                submitDate: values.date,
                splits: values.splits,
              }),
              date: values.date,
              startTime: moment(values.date)
                .hour(moment(values.startTime).hour())
                .minute(moment(values.startTime).minute()),
              endTime: moment(values.date)
                .hour(
                  moment(values.splits[values.splits.length - 1]?.date).hour()
                )
                .minute(
                  moment(values.splits[values.splits.length - 1]?.date).minute()
                ),
              hourlyWageRange,
              values: values.splits,
              doctorSubsidy: calculaterSubsidy(values.doctorSubsidy),
            })}
          </div>
        </div>
      )}
      {(!isRecruitmentGroup && isMoneyPermitted && isCreateAvailableShift) ||
      isDetailAvailableShift ? (
        <div className='tr'>
          <div className='th'>特別時給表示</div>
          <div className='td'>
            <Checkbox
              checked={values.isSpecial}
              onChange={e => {
                setFieldValue('isSpecial', e.target.checked)
              }}
            />
          </div>
        </div>
      ) : null}
      {(!isRecruitmentGroup && isMoneyPermitted && isCreateAvailableShift) ||
      isDetailAvailableShift ? (
        <div className='tr'>
          <div className='th'>早特表示</div>
          <div className='td'>
            <Checkbox
              checked={values.isEarlySpecial}
              onChange={e => {
                setFieldValue('isEarlySpecial', e.target.checked)
              }}
            />
          </div>
        </div>
      ) : null}
      {(!isRecruitmentGroup && isMoneyPermitted && isCreateAvailableShift) ||
      isDetailAvailableShift ? (
        <div className='tr'>
          <div
            style={{
              color: 'red',
            }}
            className='th'
          >
            二診目登録
          </div>
          <div className='td checkbox-color-border' >
            <Checkbox
              checked={values.isDoubleRecruitment}
              onChange={e => {
                setFieldValue('isDoubleRecruitment', e.target.checked)
              }}
            />
          </div>
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default ShiftFormSplits
