import { DOCTOR_FRAGMENTS } from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import { DEPARTMENTS_FRAGMENT } from '@medical/libs/general.graphql'
import { WORK_SCHEDULE_FRAGMENTS } from '@medical/pages/doctor/Schedule/Schedule.graphql'
import gql from 'graphql-tag'

export const WORK_SCHEDULE_COMMENT_FRAGMENTS = gql`
  fragment WORK_SCHEDULE_COMMENT_FRAGMENTS on WorkScheduleComment {
    id
    createdAt
    text
    type
    scheduledAvailableShiftGroup
    staff {
      id
      firstname
      lastname
    }
  }
`
// export const WORK_SCHEDULE_TYPE_FRAGMENTS = gql` {
//   query WORK_SCHEDULE_TYPE_FRAGMENTS ($workScheduleId: ID!) {
//     id
//  }
// `
export const DOCTOR_SUBSIDY_FRAGMENTS = gql`
  fragment DOCTOR_SUBSIDY_FRAGMENTS on DoctorSubsidy {
    id
    title
    money
  }
`
export const DETAIL_WORK_SCHEDULE = isPermitted => {
  if (isPermitted) {
    return gql`
      query DETAIL_WORK_SCHEDULE($workScheduleId: ID) {
        staffMe {
          id
        }
        workSchedule(where: { id: $workScheduleId }) {
          ...WORK_SCHEDULE_FRAGMENTS
          acceptedShift {
            id
            name
            startTime
            endTime
            splitDateTime1
            splitDateTime2
            splitDateTime3
            hourlyWage
            splitHourlyWage1
            splitHourlyWage2
            splitHourlyWage3
            isStartTimeBreakTime
            isSplitDateTime1BreakTime
            isSplitDateTime2BreakTime
            isSplitDateTime3BreakTime
            type
            introductionHistory
            introductionHistoryOther
            isPublished
            comment
            isDoubleRecruitment
            doctorSubsidy {
              id
              title
              money
            }
            scheduledAvailableShiftGroup {
              id
              name
              staffComments {
                ...WORK_SCHEDULE_COMMENT_FRAGMENTS
              }
            }
            staffComments {
              ...WORK_SCHEDULE_COMMENT_FRAGMENTS
            }
          }
          staffComments {
            ...WORK_SCHEDULE_COMMENT_FRAGMENTS
          }
          doctorWorkSchedules {
            ...WORK_SCHEDULE_FRAGMENTS
            clinicalDepartment {
              id
              name
              extraName
              isDisplay
              clinic {
                id
                order
                name
              }
            }
          }
          doctor {
            ...DOCTOR_FRAGMENTS
          }
          workScheduleActualTime {
            id
            startTime
            endTime
            splitDateTime1
            splitDateTime2
            splitDateTime3
            isStartTimeBreakTime
            isSplitDateTime1BreakTime
            isSplitDateTime2BreakTime
            isSplitDateTime3BreakTime
          }
        }
        ...DEPARTMENTS_FRAGMENT
      }
      ${WORK_SCHEDULE_FRAGMENTS}
      ${DOCTOR_FRAGMENTS}
      ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
      ${DEPARTMENTS_FRAGMENT}
    `
  }
  return gql`
    query DETAIL_WORK_SCHEDULE($workScheduleId: ID) {
      staffMe {
        id
      }
      workSchedule(where: { id: $workScheduleId }) {
        ...WORK_SCHEDULE_FRAGMENTS
        acceptedShift {
          id
          startTime
          endTime
          splitDateTime1
          splitDateTime2
          splitDateTime3
          isStartTimeBreakTime
          isSplitDateTime1BreakTime
          isSplitDateTime2BreakTime
          isSplitDateTime3BreakTime
          isPublished
          comment
        }
        staffComments {
          ...WORK_SCHEDULE_COMMENT_FRAGMENTS
        }
        doctorWorkSchedules {
          ...WORK_SCHEDULE_FRAGMENTS
          clinicalDepartment {
            id
            name
            extraName
            isDisplay
            clinic {
              id
              name
            }
          }
        }
        doctor {
          ...DOCTOR_FRAGMENTS
        }
        workScheduleActualTime {
          id
          startTime
          endTime
          splitDateTime1
          splitDateTime2
          splitDateTime3
          isStartTimeBreakTime
          isSplitDateTime1BreakTime
          isSplitDateTime2BreakTime
          isSplitDateTime3BreakTime
        }
      }
      ...DEPARTMENTS_FRAGMENT
    }
    ${WORK_SCHEDULE_FRAGMENTS}
    ${DOCTOR_FRAGMENTS}
    ${WORK_SCHEDULE_COMMENT_FRAGMENTS}
    ${DEPARTMENTS_FRAGMENT}
  `
}

export const STAFF_ACCEPT_WORK_SCHEDULE = gql`
  mutation STAFF_ACCEPT_WORK_SCHEDULE(
    $workScheduleId: ID!
    $startTime: DateTime
    $endTime: DateTime
    $hourlyWage: Int
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $acceptedReason: String
    $introductionHistory: IntroductionHistoryType
    $introductionHistoryOther: String
    $doctorSubsidy: [DoctorSubsidyForCreateInput!]
    $isDoubleRecruitment: Boolean
  ) {
    staffAcceptWorkSchedule(
      workScheduleId: $workScheduleId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      acceptedReason: $acceptedReason
      introductionHistory: $introductionHistory
      introductionHistoryOther: $introductionHistoryOther
      doctorSubsidy: $doctorSubsidy
      isDoubleRecruitment: $isDoubleRecruitment
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const STAFF_REJECT_WORK_SCHEDULE = gql`
  mutation STAFF_REJECT_WORK_SCHEDULE(
    $workScheduleId: ID!
    $rejectedReason: String
  ) {
    staffRejectWorkSchedule(
      workScheduleId: $workScheduleId
      rejectedReason: $rejectedReason
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const STAFF_CANCEL_WORK_SCHEDULE = gql`
  mutation STAFF_CANCEL_WORK_SCHEDULE(
    $workScheduleId: ID!
    $deletedReason: String
  ) {
    staffCancelAcceptedWorkSchedule(
      workScheduleId: $workScheduleId
      deletedReason: $deletedReason
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`
export const STAFF_RESTORE_WORK_SCHEDULE = gql`
  mutation STAFF_RESTORE_WORK_SCHEDULE($workScheduleId: ID!) {
    staffRestoreWorkSchedule(workScheduleId: $workScheduleId) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`
export const STAFF_UPDATE_WORK_SCHEDULE = gql`
  mutation UPDATE_WORK_SCHEDULE(
    $workScheduleId: ID!
    $startTime: DateTime
    $endTime: DateTime
    $hourlyWage: Int
    $splitDateTime1: DateTime
    $splitDateTime2: DateTime
    $splitDateTime3: DateTime
    $splitHourlyWage1: Int
    $splitHourlyWage2: Int
    $splitHourlyWage3: Int
    $isStartTimeBreakTime: Boolean
    $isSplitDateTime1BreakTime: Boolean
    $isSplitDateTime2BreakTime: Boolean
    $isSplitDateTime3BreakTime: Boolean
    $acceptedReason: String
    $introductionHistory: IntroductionHistoryType
    $introductionHistoryOther: String
    $staffComments: [WorkScheduleCommentCreateInput!]
    $comment: String
    $isSendMail: Boolean
    $doctorSubsidy: [DoctorSubsidyForCreateInput!]
    $isPublished: Boolean
    $isDoubleRecruitment: Boolean
  ) {
    staffUpdateWorkSchedule(
      staffComments: $staffComments
      workScheduleId: $workScheduleId
      startTime: $startTime
      endTime: $endTime
      hourlyWage: $hourlyWage
      splitDateTime1: $splitDateTime1
      splitDateTime2: $splitDateTime2
      splitDateTime3: $splitDateTime3
      splitHourlyWage1: $splitHourlyWage1
      splitHourlyWage2: $splitHourlyWage2
      splitHourlyWage3: $splitHourlyWage3
      isStartTimeBreakTime: $isStartTimeBreakTime
      isSplitDateTime1BreakTime: $isSplitDateTime1BreakTime
      isSplitDateTime2BreakTime: $isSplitDateTime2BreakTime
      isSplitDateTime3BreakTime: $isSplitDateTime3BreakTime
      acceptedReason: $acceptedReason
      introductionHistory: $introductionHistory
      introductionHistoryOther: $introductionHistoryOther
      comment: $comment
      isSendMail: $isSendMail
      doctorSubsidy: $doctorSubsidy
      isPublished: $isPublished
      isDoubleRecruitment: $isDoubleRecruitment
    ) {
      ...WORK_SCHEDULE_FRAGMENTS
    }
  }
  ${WORK_SCHEDULE_FRAGMENTS}
`

export const ACTIVITIES_HISTORY = gql`
  query ACTIVITIES_HISTORY($where: ActivityHistoryWhereInput) {
    activityHistories(where: $where) {
      id
      createdAt
      activity
      staff {
        id
        firstname
        lastname
      }
    }
  }
`
export const STAFF_DELETE_DOCTOR_SUBSIDY = gql`
  mutation StaffDeleteDoctorSubsidy($doctorSubsidyId: ID!) {
    staffDeleteDoctorSubsidy(doctorSubsidyId: $doctorSubsidyId)
  }
`
