import { useCustom } from '@medical/provider/context'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const DoctorLink = ({ history, children, ...props }) => {
  const [, { setMenuToggle }] = useCustom()

  const { to, className } = props

  const onClick = e => {
    e.preventDefault()
    setMenuToggle(false)
    history.push(to)
  }

  return (
    <Link to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  )
}

export default withRouter(DoctorLink)
