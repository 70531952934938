import { useMutation } from '@apollo/react-hooks'
import { Step1, Step2, Step3 } from '@medical/components/Forms/SignupComponent'
import SignupProgress from '@medical/components/SignupProgress'
import Auth from '@medical/middleware/auth'
import { useCustom } from '@medical/provider/context'
import HomeRedirect from '@medical/routes/HomeRedirect'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { createRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { CHECK_DOCTOR_REGISTER, DOCTOR_SIGNUP } from './DoctorSignup.graphql'

const DoctorSignup = ({ history }) => {
  const [doctorSignup] = useMutation(DOCTOR_SIGNUP)
  const [staffCheckDoctor, { loading: staffCheckDoctorLoading }] = useMutation(
    CHECK_DOCTOR_REGISTER
  )
  const [{ i18n, popup }] = useCustom()
  const listSteps = {
    step1: 0,
    step2: 1,
    step3: 2,
  }

  const [currentStep, setCurrentStep] = useState(listSteps.step1)
  const recaptcha = createRef()
  const [stateData, setStateData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    firstnameKana: '',
    lastnameKana: '',
    gender: '',
    birthday: '1975-1-1',
    medicalLicenseNumber: '',
    departments: [],
    stateOrRegion: '',
    address1: '',
    address2: '',
    zipCodeFirst: '',
    zipCodeSecond: '',
    phoneNumberFirst: '',
    phoneNumberSecond: '',
    phoneNumberThird: '',
    nearestStations: [],
    placeOfWork: '',
    graduationUniversity: '',
    graduationUniversityYear: '',
    obtainDoctorLicenseYear: '',
    professionalDescription: '',
    isIntroduce: '',
    introducedBy: '',
    file: '',
    confirm: false,
    fileCategory: 'MEDICAL_DOCTOR_REGISTRATION',
    isJoinedSublingualImmunotherapyELearning: '',
    isJoinedOnlineDiagnosticTraining: '',
    reasonKnowWebsites: [],
    otherReasonKnowWebsite: '',
    careerHistory: [],
    expectedDateInterview: [
      {
        date: '',
        startTime: '',
        endTime: '',
        selectTime: 1,
        expectTime: [],
      },
      {
        date: '',
        startTime: '',
        endTime: '',
        selectTime: 1,
        expectTime: [],
      },
      {
        date: '',
        startTime: '',
        endTime: '',
        selectTime: 1,
        expectTime: [],
      },
    ],
    skillSet: [],
    province: '',
    filename: undefined,
    mimetype: undefined,
  })

  if (Auth.getToken()) {
    return <HomeRedirect />
  }

  const handleSubmitSignup = async (data, { setSubmitting }) => {
    try {
      const variable = {
        ...stateData,
        ...data,
        phoneNumber: `${stateData.phoneNumberFirst}${stateData.phoneNumberSecond}${stateData.phoneNumberThird}`,
        zipCode: `${stateData.zipCodeFirst}${stateData.zipCodeSecond}`,
        stateOrRegion: stateData.stateOrRegion.name_ja
          ? stateData.stateOrRegion.name_ja
          : stateData.stateOrRegion,
        isJoinedOnlineDiagnosticTraining:
          data.isJoinedOnlineDiagnosticTraining[0],
        isJoinedSublingualImmunotherapyELearning:
          data.isJoinedSublingualImmunotherapyELearning[0],
        provinces: stateData.province,
      }
      if (variable.careerHistory.length === 0) delete variable.careerHistory
      await doctorSignup({
        variables: variable,
      })
      history.push('./signup_success')
    } catch (error) {
      popup.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  const handleNextStep = async data => {
    try {
      if (currentStep === 0) {
        const {
          tokenResponse,
          medicalLicenseNumber,
          birthday,
          firstnameKana,
          lastnameKana,
          firstname,
          lastname,
          email,
        } = data
        const birthdayFormat = `${
          moment(birthday)
            .format('YYYY-MM-DDTHH:mm:ss')
            .split('+')[0]
        }.000Z`
        const { data: dataCheckDoctor, err } = await staffCheckDoctor({
          variables: {
            tokenResponse,
            lastnameKana,
            firstnameKana,
            firstname,
            lastname,
            email,
            medicalLicenseNumber,
            birthday: birthdayFormat,
          },
        })
        if (err) {
          throw err
        }
        if (dataCheckDoctor.staffCheckDoctor) {
          setCurrentStep(currentStep + 1)
          setStateData({
            ...stateData,
            ...data,
          })
        } else {
          history.push('./warning_register')
        }
      }
      if (currentStep === 1) {
        setCurrentStep(currentStep + 1)
        setStateData({
          ...stateData,
          ...data,
        })
      }
    } catch (err) {
      popup.error(err)
    }

    // end fc block
  }

  const handleBackStep = data => {
    if (currentStep === 1 || currentStep === 2) {
      setCurrentStep(currentStep - 1)
      setStateData({
        ...stateData,
        ...data,
      })
    }
  }

  const renderFieldByStep = step => {
    switch (step) {
      case listSteps.step1:
        return (
          <Step1
            handleNextStep={handleNextStep}
            stateData={stateData}
            staffCheckDoctorLoading={staffCheckDoctorLoading}
            ref={recaptcha}
          />
        )

      case listSteps.step2:
        return (
          <Step2
            handleBackStep={handleBackStep}
            handleNextStep={handleNextStep}
            stateData={stateData}
          />
        )

      case listSteps.step3:
        return (
          <Step3
            handleBackStep={handleBackStep}
            handleSubmitSignup={handleSubmitSignup}
            stateData={stateData}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='signup-bg'>
      <div className='signup-box'>
        <div className='signup-title'>{i18n.t('doctor.signup.title')}</div>
        <div className='signup-subTitle'>
          {i18n.t('doctor.signup.subTitle')}
          <Link to={Router.doctorLogin}>{i18n.t('doctor.signup.login')}</Link>
        </div>
        <SignupProgress step={currentStep} />
        {renderFieldByStep(currentStep)}
      </div>
    </div>
  )
}

export default DoctorSignup
