import {
  ENUM_ACCEPTED_WS,
  ENUM_MASS_RECRUITMENT,
  ENUM_AVAILABLE_SHIFT,
  ENUM_CANCELED_WS,
  ENUM_NOT_ACCEPTED_WS,
  ENUM_UNCONFIRMED_SHIFT,
} from '@medical/constant'
import { checkStaffPermission, dateValidation, isHolidays } from '@medical/libs'
import {
  ShiftDateSelectors,
  ShiftHeader,
} from '@medical/pages/staff/components'
import Router from '@medical/routes/router'
import { Dropdown, Radio } from 'antd'
import moment from 'moment'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import { useState } from 'react'
import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
} from 'react-calendar-timeline'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import { Link } from 'react-router-dom'

import itemRenderer from './components/ItemRender'
import styles from './Style'
import {
  QUERY_ACCEPTED_WORK_SCHEDULES,
  QUERY_AVAILABLE_SHIFTS,
  QUERY_WORK_SCHEDULES,
  STAFF_CREATE_ACCEPTED_WS,
  STAFF_CREATE_AS_GROUP,
  STAFF_CREATE_SHIFT,
  STAFF_UPLOAD_SHIFT,
} from '@medical/constant/permissions'

const getValueRadio = statuses => {
  if (statuses?.includes(ENUM_AVAILABLE_SHIFT)) return ENUM_AVAILABLE_SHIFT
  if (statuses?.includes(ENUM_UNCONFIRMED_SHIFT)) return ENUM_UNCONFIRMED_SHIFT
  if (statuses?.includes(ENUM_MASS_RECRUITMENT)) return ENUM_MASS_RECRUITMENT
  return null
}

const DailyAvailableShiftScene = ({
  i18n,
  groups,
  items,
  date,
  statuses,
  search,
  onCheckboxChange,
  history,
  url,
  holidays,
  isNewWS,
  onRegionChange,
  onClinicChange,
  onDepartmentChange,
  listRegions,
  regionIds,
  uniqueClinicName,
  clinicIds,
  departmentNames,
  clinicalDepartmentNamesFilter,
}) => {
  const [value, setValue] = useState(getValueRadio(statuses))
  const prevDate = moment(date).subtract(1, 'day')
  const nextDate = moment(date).add(1, 'day')
  const isPreviousDisabled =
    date.isSameOrBefore(moment().subtract(1, 'years'), 'month') &&
    dateValidation({
      year: prevDate.year(),
      month: prevDate.month() + 1,
      day: prevDate.date(),
    })

  const isNextDisabled =
    dateValidation({
      year: nextDate.year(),
      month: nextDate.month() + 1,
      day: nextDate.date(),
    }) && date.isSameOrAfter(moment().add(6, 'quarters'), 'month')
  const isSelectedDateHoliday = isHolidays({ holidays, date })
  const listValueRadio = [
    { value: ENUM_AVAILABLE_SHIFT, label: '全て表示' },
    { value: ENUM_UNCONFIRMED_SHIFT, label: '未充足、半未充足シフトを表示' },
    { value: ENUM_MASS_RECRUITMENT, label: '未充足シフトのみ表示' },
  ]
  const menu = (
    <Radio.Group
      onChange={e => {
        setValue(e.target.value)
        onCheckboxChange(
          { value: e.target.value },
          listValueRadio
            .map(({ value }) => value)
            .filter(item => item !== e.target.value)
        )
      }}
      value={value}
    >
      <div
        style={{
          width: '250px',
          height: '125px',
          backgroundColor: 'white',
          boxShadow: '5px 5px 12px #888888',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          padding: '10px 10px 10px 20px',
          position: 'absolute',
          left: '-45px',
          top: '11px',
        }}
      >
        {listValueRadio.map(({ value, label }) => (
          <Radio
            key={value}
            value={value}
            checked={false}
            style={{
              marginRight: '0',
              inlineSize: 'inherit',
              fontSize: '13px',
            }}
          >
            <span>{label}</span>
          </Radio>
        ))}
      </div>
    </Radio.Group>
  )

  const isQueryAcceptedWorkSchedulePermitted = checkStaffPermission({
    functionName: QUERY_ACCEPTED_WORK_SCHEDULES,
  })
  const isQueryWorkSchedulePermitted = checkStaffPermission({
    functionName: QUERY_WORK_SCHEDULES,
  })
  const isQueryAvailableShiftPermitted = checkStaffPermission({
    functionName: QUERY_AVAILABLE_SHIFTS,
  })
  const isUploadPermitted = checkStaffPermission({
    functionName: STAFF_UPLOAD_SHIFT,
  })
  const isCreateShiftPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_SHIFT,
  })
  const isCreateSchedulePermitted = checkStaffPermission({
    functionName: STAFF_CREATE_ACCEPTED_WS,
  })
  const isCreateScheduledShiftGroupPermitted = checkStaffPermission({
    functionName: STAFF_CREATE_AS_GROUP,
  })

  return (
    <div>
      <ShiftHeader
        items={[
          {
            to: Router.get(Router.staffMonthlyAvailableShifts, {
              year: date.clone().year(),
              month: date.clone().month() + 1,
            }),
            title: moment(date).format('YYYY年MM月'),
          },
          { title: moment(date).format('DD日') },
        ]}
        isCreateShiftPermitted={isCreateShiftPermitted}
        isUploadPermitted={isUploadPermitted}
        isCreateSchedulePermitted={isCreateSchedulePermitted}
        isCreateScheduledShiftGroupPermitted={
          isCreateScheduledShiftGroupPermitted
        }
      />
      <div className='box pt-3'>
        <div className='staff-download-buttons-daily mb-3'>
          {isQueryAvailableShiftPermitted ? (
            <Link
              to={{
                pathname: Router.get(Router.staffAvailableShiftList, {
                  year: date.clone().year(),
                  month: date.clone().month() + 1,
                }),
              }}
            >
              <Button
                icon='pi pi-list'
                className='p-button-normal-empty'
                label={i18n.t('main.availableShiftList')}
              />
            </Link>
          ) : null}
          {isQueryWorkSchedulePermitted ? (
            <>
              {isNewWS ? (
                <>
                  <Link
                    to={{
                      pathname: Router.get(Router.staffWorkSchedulesList, {
                        year: date.clone().year(),
                        month: date.clone().month() + 1,
                      }),
                    }}
                  >
                    <button className='p-button p-component p-button-normal-available p-button-text-icon-left'>
                      <span className='pi pi-list p-c p-button-icon-left'></span>
                      <span
                        className='p-button-text p-c'
                        style={{ display: 'inline-block' }}
                      >
                        {i18n.t('main.workScheduleList')}
                      </span>
                      <span className='new-ws'>new</span>
                    </button>
                  </Link>
                </>
              ) : (
                <Link
                  to={{
                    pathname: Router.get(Router.staffWorkSchedulesList, {
                      year: date.clone().year(),
                      month: date.clone().month() + 1,
                    }),
                  }}
                >
                  <Button
                    icon='pi pi-list'
                    className='p-button-normal-available'
                    label={i18n.t('main.workScheduleList')}
                  />
                </Link>
              )}
            </>
          ) : null}
          {isQueryAcceptedWorkSchedulePermitted ? (
            <Link
              to={{
                pathname: Router.get(Router.staffAcceptedWorkSchedulesList, {
                  year: date.clone().year(),
                  month: date.clone().month() + 1,
                }),
              }}
            >
              <Button
                icon='pi pi-list'
                className='p-button-normal'
                label={i18n.t('main.acceptedWorkScheduleList')}
              />
            </Link>
          ) : null}
        </div>
        <div style={styles.header}>
          <div style={styles.headers}>{i18n.t('main.dailyShiftsTitle')}</div>
          <Link
            to={{
              pathname: Router.get(Router.staffDailyAvailableShifts, {
                year: prevDate.year(),
                month: prevDate.month() + 1,
                day: prevDate.date(),
              }),
              search,
            }}
            onClick={isPreviousDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-left' disabled={isPreviousDisabled} />
          </Link>
          {isSelectedDateHoliday ? (
            <div style={styles.title}>{`${moment(date).format(
              'LL (dd)'
            )}(祝)`}</div>
          ) : (
            <div style={styles.title}>{moment(date).format('LL (dd)')}</div>
          )}
          <Link
            to={{
              pathname: Router.get(Router.staffDailyAvailableShifts, {
                year: nextDate.year(),
                month: nextDate.month() + 1,
                day: nextDate.date(),
              }),
              search,
            }}
            onClick={isNextDisabled ? e => e.preventDefault() : undefined}
          >
            <Button icon='pi pi-chevron-right' disabled={isNextDisabled} />
          </Link>

          <div style={styles.dates}>
            <ShiftDateSelectors search={search} />
          </div>
        </div>
        {!date.isSameOrBefore(moment().subtract(13, 'months'), 'month') ? (
          <div className='overflow-container'>
            <div style={{ minWidth: 1000 }}>
              <div className='search-parent pb-0 pt-0'>
                <div
                  className='search-item'
                  style={{ position: 'relative', marginLeft: '0' }}
                >
                  <MultiSelect
                    options={listRegions}
                    optionLabel='region'
                    value={listRegions.filter(({ id }) =>
                      regionIds.includes(id)
                    )}
                    fixedPlaceholder
                    onChange={onRegionChange}
                    filter
                    placeholder={i18n.t('main.areaSelect')}
                    className='text-nowrap'
                  />
                  <MultiSelect
                    options={uniqueClinicName}
                    optionLabel='clinicName'
                    value={uniqueClinicName.filter(({ id }) =>
                      clinicIds.includes(id)
                    )}
                    onChange={onClinicChange}
                    placeholder={i18n.t(
                      'staff.waitingRecruitment.clinicSelect'
                    )}
                    fixedPlaceholder
                    filter
                  />
                  <MultiSelect
                    options={departmentNames}
                    optionLabel='departmentName'
                    value={departmentNames.filter(({ name }) =>
                      clinicalDepartmentNamesFilter.includes(name)
                    )}
                    onChange={onDepartmentChange}
                    placeholder={i18n.t(
                      'staff.waitingRecruitment.departmentSelect'
                    )}
                    fixedPlaceholder
                    filter
                  />
                  {/* <div style={{ maxWidth: 280 }}>
                    <MultiSelect
                      value={clinicalDepartments.filter(({ id }) =>
                        clinicalDepartmentIds.includes(id)
                      )}
                      fixedPlaceholder
                      options={clinicalDepartments}
                      optionLabel='clinicName'
                      onChange={onDropdownChange}
                      filter
                      placeholder={i18n.t('main.chooseClinic')}
                      className='text-nowrap'
                    />
                  </div> */}
                  <Dropdown overlay={menu} placement='bottomRight'>
                    <div className='search-item'>
                      <Checkbox
                        inputId='cb1'
                        checked={
                          statuses.includes(ENUM_AVAILABLE_SHIFT) ||
                          statuses.includes(ENUM_UNCONFIRMED_SHIFT) ||
                          statuses.includes(ENUM_MASS_RECRUITMENT)
                        }
                        onChange={e => {
                          if (!e.checked) {
                            setValue(null)
                            onCheckboxChange(
                              { value: null },
                              listValueRadio.map(({ value }) => value)
                            )
                          }
                        }}
                      />{' '}
                      <label
                        htmlFor='cb1'
                        className='p-checkbox-label text-nowrap'
                      >
                        {i18n.t('main.availableShiftEmpty')}
                      </label>
                    </div>
                  </Dropdown>
                  <div className='search-item'>
                    <Checkbox
                      inputId='cb2'
                      value={ENUM_NOT_ACCEPTED_WS}
                      checked={statuses.includes('NotAcceptedWorkSchedule')}
                      onChange={onCheckboxChange}
                    />

                    <label
                      htmlFor='cb2'
                      className='p-checkbox-label text-nowrap'
                    >
                      {i18n.t('main.availableShiftOrdered')}
                    </label>
                  </div>
                  <div className='search-item'>
                    <Checkbox
                      inputId='cb3'
                      value={ENUM_ACCEPTED_WS}
                      checked={statuses.includes('AcceptedWorkSchedule')}
                      onChange={onCheckboxChange}
                    />
                    <label
                      htmlFor='cb3'
                      className='p-checkbox-label text-nowrap'
                    >
                      {i18n.t('main.availableShiftAccepted')}
                    </label>
                  </div>
                  <div className='search-item'>
                    <Checkbox
                      inputId='cb4'
                      value={ENUM_CANCELED_WS}
                      checked={statuses.includes('CanceledWorkSchedule')}
                      onChange={onCheckboxChange}
                    />
                    <label
                      htmlFor='cb4'
                      className='p-checkbox-label text-nowrap'
                    >
                      {i18n.t('main.workScheduleCanceled')}
                    </label>
                  </div>
                  {/* <div className='search-item'>
                    <Checkbox
                      inputId='cb5'
                      value={ENUM_UNCONFIRMED_SHIFT}
                      checked={statuses.includes('UnconfirmedShift')}
                      onChange={onCheckboxChange}
                    />
                    <label htmlFor='cb5' className='p-checkbox-label'>
                      {i18n.t('main.unconfirmedShift')}
                    </label>
                  </div> */}
                </div>
              </div>

              <div className='statusTips'>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-secondexam' />
                  {i18n.t('main.availableShiftSecondExam')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-empty' />
                  {i18n.t('main.availableShiftEmpty')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-ordered' />
                  {i18n.t('main.availableShiftOrdered')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-accepted' />
                  {i18n.t('main.availableShiftAccepted')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-fixed' />
                  {i18n.t('main.workScheduleType.workScheduleFixed')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-canceled' />
                  {i18n.t('main.workScheduleCanceled')}
                </div>
                <div className='item-tips'>
                  <span className='staff-shift staff-shift-break' />
                  {i18n.t('main.availableShiftBreak')}
                </div>
              </div>

              <div className='react-calendar-timeline-container'>
                <Timeline
                  groups={groups}
                  items={items}
                  visibleTimeStart={
                    date
                      .clone()
                      .add(7, 'hour')
                      .unix() * 1000
                  }
                  visibleTimeEnd={
                    date
                      .clone()
                      .add(1, 'day')
                      .unix() * 1000
                  }
                  canMove={false}
                  stackItems
                  sidebarWidth={400}
                  resizeDetector={containerResizeDetector}
                  itemRenderer={itemRenderer}
                  itemTouchSendsClick
                  onItemClick={itemId => {
                    const item = items.find(({ id }) => id === itemId)
                    history.push({
                      pathname: `${url}${item.pathname}`,
                      search,
                      state: {
                        acceptedShiftId: item?.acceptedShiftId,
                      },
                    })
                  }}
                  style={{
                    maxHeight: 'calc(100vh - 212px)',
                    overflow: 'auto',
                  }}
                >
                  <TimelineHeaders className='sticky'>
                    <SidebarHeader>
                      {({ getRootProps }) => (
                        <div {...getRootProps()} className='tr'>
                          <div
                            style={{
                              width: '20%',
                              paddingLeft: 5,
                              textAlign: 'center',
                              fontSize: '12px',
                            }}
                          >
                            {i18n.t('main.areaSelect')}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              paddingLeft: 5,
                              textAlign: 'center',
                              fontSize: '12px',
                            }}
                          >
                            {i18n.t('main.clinicName')}
                          </div>
                          <div
                            style={{
                              width: '20%',
                              textAlign: 'center',
                              fontSize: '12px',
                            }}
                          >
                            {i18n.t('main.departmentName-short')}
                          </div>
                          <div
                            style={{
                              width: '40%',
                              textAlign: 'center',
                              fontSize: '12px',
                            }}
                          >
                            {i18n.t('staff.editClinic.openTime')}
                          </div>
                        </div>
                      )}
                    </SidebarHeader>
                    <DateHeader unit='primaryHeader' />
                    <DateHeader labelFormat='HH:mm' height={50} />
                  </TimelineHeaders>
                </Timeline>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default DailyAvailableShiftScene
