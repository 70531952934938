import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { pathOr } from 'ramda'
import React from 'react'
import { useHistory } from 'react-router-dom';

const CustomDropdown = ({
  label,
  values,
  errors,
  touched,
  name,
  options = [],
  setFieldValue,
  optionLabel = 'description',
  valueLabel = 'value',
  disabled,
  classDisabled,
  type,
  required,
  onlyStaff,
  placeholder,
  style = {},
  isClinic = false,
  isClinicUpdate
}) => {
  const history = useHistory();
  const isTouched = pathOr(false, [name], touched)
  const error = pathOr('', [name], errors)
  return (
    <div className='tr'>
      <div
        className={`th ${required ? 'required' : ''} ${
          onlyStaff ? 'onlyStaff' : ''
        }`}
      >
        {label}
      </div>
      <div className='td' style={style}>
        <Dropdown
          style={{ width: '100%' }}
          name={name}
          value={options.find(option => {
            const value = type ? values[name][valueLabel] : values[name]
            return option[valueLabel] === value
          })}
          options={options}
          optionLabel={optionLabel}
          onChange={e => {
            const value = type ? e.value : e.value[valueLabel]
            setFieldValue(name, value)
          }}
          disabled={disabled}
          className={classDisabled && disabled ? 'is-disabled' : ''}
          placeholder={placeholder}
        />
        <div className='alert'>{isTouched && error}</div>
      </div>

      {isClinic && isClinicUpdate ? (
        <>
            <Button
              icon='pi pi-pencil'
              onClick={()=>history.push('/staff/clinics/region')}
            />
        </>
      ) : null}
    </div>
  )
}

export default CustomDropdown
