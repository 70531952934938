import { useMutation, useQuery } from '@apollo/react-hooks'
import { ErrorComponent, NotFound, ProgressSpinner } from '@medical/components'
import {
  SHOW_MONEY_FIELDS,
  STAFF_ACCEPTED_WS,
  STAFF_ADD_COMMENT,
  STAFF_CANCEL_ACCEPTED_WS,
  STAFF_DELETE_COMMENT,
  STAFF_REJECT_WS,
  STAFF_RESTORE_WS,
  STAFF_UPDATE_WS,
  STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
  STAFF_DELETE_ALL_COMMENT
} from '@medical/constant/permissions'
import {
  checkStaffPermission,
  clinicalDepartmentNames,
  splitLogic,
} from '@medical/libs'
import { checkIsGoldWare } from '@medical/libs/checkIsGoldWare'
import { CREATE_ACTIVITY } from '@medical/pages/doctor/AvailableShift/AvailableShift.graphql'
import { AVAILABLE_SHIFT_COMMENT } from '@medical/pages/doctor/SingleSchedule/SingleSchedule.graphql'
import { CLINICS_DAILY_REPORT } from '@medical/pages/staff/DailyAvailableShift/DailyAvailableShift.graphql'
import { useCustom } from '@medical/provider/context'
import Router from '@medical/routes/router'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NO_DOCTOR_WORKED_IN_CLINIC } from '../Clinics/GuidanceMessage/GuidanceMessage.grapql'
import { activityLogHistories } from '@medical/libs/activityLogHistory'
import {
  ACTIVITIES_HISTORY,
  DETAIL_WORK_SCHEDULE,
  STAFF_ACCEPT_WORK_SCHEDULE,
  STAFF_CANCEL_WORK_SCHEDULE,
  STAFF_REJECT_WORK_SCHEDULE,
  STAFF_RESTORE_WORK_SCHEDULE,
  STAFF_UPDATE_WORK_SCHEDULE,
} from './DetailWorkSchedule.graphql'
import DetailWorkScheduleScene from './DetailWorkScheduleScene'
import queryString from 'query-string'

const accept = 'accept'
const cancel = 'cancel'
const reject = 'reject'
const restore = 'restore'
const update = 'update'

const DetailWorkSchedule = ({
  closeModal,
  parentPath,
  history,
  location: { state, search },
  match: {
    params: { workScheduleId, year, month, day },
  },
}) => {
  const activities = [
    {
      value: 'UPDATED_CONFIRMED_SHIFTS_PUBLISHED',
      description: '掲載',
    },
    {
      value: 'UPDATED_CONFIRMED_SHIFTS_NOT_PUBLISHED',
      description: '未掲載',
    },
  ]
  const { acceptedShiftId } = queryString.parse(search)
  const updateActivities = [
    {
      value: 'CREATE_FIXED_SHIFTS',
      description: '個別確定シフト作成',
    },
    {
      value: 'UPDATE_FIXED_SHIFTS',
      description: '個別確定シフト更新',
    },
    {
      value: 'CREATE_FIXED_SHIFTS_GROUP',
      description: '定期確定シフト作成',
    },
    {
      value: 'UPDATE_FIXED_SHIFTS_GROUP',
      description: '定期確定シフト更新',
    },
  ]
  const date = moment()
    .startOf('day')
    .year(year)
    .month(month - 1)
    .date(day || 1)
  const [{ i18n, popup }] = useCustom()
  const isMoneyPermitted = checkStaffPermission({
    functionName: SHOW_MONEY_FIELDS,
  })
  const isUpdatePermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_WS,
  })
  const isRejectPermitted = checkStaffPermission({
    functionName: STAFF_REJECT_WS,
  })
  const isCancelPermitted = checkStaffPermission({
    functionName: STAFF_CANCEL_ACCEPTED_WS,
  })
  const isRestorePermitted = checkStaffPermission({
    functionName: STAFF_RESTORE_WS,
  })
  const isAcceptPermitted = checkStaffPermission({
    functionName: STAFF_ACCEPTED_WS,
  })
  const isAddPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeletePermitted = checkStaffPermission({
    functionName: STAFF_DELETE_COMMENT,
  })
  const isChangeConfirmedShiftsStatusPermitted = checkStaffPermission({
    functionName: STAFF_UPDATE_CONFIRMED_SHIFTS_STATUS,
  })
  const isAddCommentPermitted = checkStaffPermission({
    functionName: STAFF_ADD_COMMENT,
  })
  const isDeleteAllComment = checkStaffPermission({
    functionName: STAFF_DELETE_ALL_COMMENT,
  })

  const isGoldWare = checkIsGoldWare()

  const [acceptedShiftID, setAcceptedShiftID] = useState('')

  const { loading, error, data, refetch } = useQuery(
    DETAIL_WORK_SCHEDULE(isMoneyPermitted),
    {
      variables: {
        workScheduleId,
      },
      fetchPolicy: 'network-only',
    }
  )

  const {
    loading: noDoctorWorkedLoading,
    error: noDoctorWorkedError,
    data: noDoctorWorkedData,
  } = useQuery(NO_DOCTOR_WORKED_IN_CLINIC, {
    skip: !data || !!data.workSchedule?.accepted,
    variables: {
      doctorId: data ? data.workSchedule?.doctor.id : undefined,
      clinicId: data
        ? data.workSchedule?.clinicalDepartment.clinic.id
        : undefined,
    },
  })

  const {
    loading: acLoading,
    error: acError,
    data: acData,
    refetch: refetchActivities,
  } = useQuery(ACTIVITIES_HISTORY, {
    variables: {
      orderBy: 'createdAt_ASC',
      where: {
        availableShift: acceptedShiftID,
      },
    },
  })
  const [staffCreateActivity] = useMutation(CREATE_ACTIVITY)
  const mutationOptions = {
    refetchQueries: [
      {
        query: CLINICS_DAILY_REPORT,
        variables: {
          date,
        },
      },
    ],
    awaitRefetchQueries: true,
  }
  const [acceptWorkSchedule] = useMutation(
    STAFF_ACCEPT_WORK_SCHEDULE,
    mutationOptions
  )
  const [rejectWorkSchedule] = useMutation(
    STAFF_REJECT_WORK_SCHEDULE,
    mutationOptions
  )
  const [cancelWorkSchedule] = useMutation(
    STAFF_CANCEL_WORK_SCHEDULE,
    mutationOptions
  )

  const [restoreWorkSchedule] = useMutation(
    STAFF_RESTORE_WORK_SCHEDULE,
    mutationOptions
  )
  const [updateWorkSchedule] = useMutation(
    STAFF_UPDATE_WORK_SCHEDULE,
    mutationOptions
  )

  const {
    loading: availableShiftLoading,
    error: availableShiftError,
    data: availableShiftComment,
    refetch: refetchComment,
  } = useQuery(AVAILABLE_SHIFT_COMMENT, {
    variables: {
      availableShiftsWhere: {
        WorkSchedule: {
          id: workScheduleId,
        },
      },
    },
  })

  useEffect(() => {
    refetch()
    refetchComment()
    if (data && data.workSchedule) {
      setAcceptedShiftID(data.workSchedule?.acceptedShift.id)
      refetchActivities()
    }
  })
  if (loading || availableShiftLoading || acLoading || noDoctorWorkedLoading)
    return <ProgressSpinner />
  if (error || availableShiftError || noDoctorWorkedError || acError)
    return (
      <ErrorComponent
        error={error || availableShiftError || acError || noDoctorWorkedError}
      />
    )
  const submitFunctions = {
    [accept]: async ({
      startTime,
      endTime,
      hourlyWage,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      acceptedReason,
      introductionHistory,
      introductionHistoryOther,
      doctorSubsidy,
      isPublished,
      isDoubleRecruitment
    }) =>
      acceptWorkSchedule({
        variables: {
          workScheduleId,
          startTime,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          acceptedReason,
          introductionHistory:
            introductionHistory !== '' ? introductionHistory : null,
          introductionHistoryOther,
          doctorSubsidy,
          isPublished,
          isDoubleRecruitment
        },
      }),
    [reject]: async ({ rejectedReason }) =>
      rejectWorkSchedule({
        variables: {
          workScheduleId,
          rejectedReason,
        },
      }),
    [cancel]: async ({ deletedReason }) =>
      cancelWorkSchedule({
        variables: {
          workScheduleId,
          deletedReason,
        },
      }),
    [restore]: async () =>
      restoreWorkSchedule({
        variables: {
          workScheduleId,
        },
      }),

    [update]: async ({
      startTime,
      endTime,
      hourlyWage,
      splitDateTime1,
      splitDateTime2,
      splitDateTime3,
      splitHourlyWage1,
      splitHourlyWage2,
      splitHourlyWage3,
      isStartTimeBreakTime,
      isSplitDateTime1BreakTime,
      isSplitDateTime2BreakTime,
      isSplitDateTime3BreakTime,
      acceptedReason,
      introductionHistory,
      introductionHistoryOther,
      staffComments,
      comment,
      isSendMail,
      doctorSubsidy,
      isPublished,
      isDoubleRecruitment
    }) =>
      updateWorkSchedule({
        variables: {
          workScheduleId,
          startTime,
          endTime,
          hourlyWage,
          splitDateTime1,
          splitDateTime2,
          splitDateTime3,
          splitHourlyWage1,
          splitHourlyWage2,
          splitHourlyWage3,
          isStartTimeBreakTime,
          isSplitDateTime1BreakTime,
          isSplitDateTime2BreakTime,
          isSplitDateTime3BreakTime,
          acceptedReason,
          introductionHistory:
            introductionHistory !== '' ? introductionHistory : null,
          introductionHistoryOther,
          staffComments,
          isSendMail,
          comment: comment || undefined,
          doctorSubsidy,
          isPublished,
          isDoubleRecruitment
        },
      }),
  }

  const onSubmit = async (
    {
      functionName,
      clinicalDepartment,
      startTime,
      splits,
      deletedReason,
      acceptedReason,
      rejectedReason,
      introductionHistory,
      introductionHistoryOther,
      comment,
      isSendMail,
      doctorId,
      departmentName,
      clinicName,
      doctorSubsidy,
      isPublished,
      isDoubleRecruitment,
    },
    { setSubmitting, resetForm }
  ) => {
    for (let i = 0; i < doctorSubsidy.length; i += 1) {
      delete doctorSubsidy[i].__typename
    }
    let successMessage
    switch (functionName) {
      case accept:
        successMessage = i18n.t('staff.acceptWorkSchedule.submissionSuccess')
        break
      case cancel:
        successMessage = i18n.t('staff.cancelWorkSchedule.submissionSuccess')
        break
      case reject:
        successMessage = i18n.t('staff.rejectWorkSchedule.submissionSuccess')
        break
      case restore:
        successMessage = i18n.t('staff.restoreWorkSchedule.submissionSuccess')
        break
      case update:
        successMessage = i18n.t('staff.updateWorkSchedule.submissionSuccess')
        break
      default:
        break
    }
    try {
      const {
        endTime,
        hourlyWage,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        splitHourlyWage1,
        splitHourlyWage2,
        splitHourlyWage3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
      } = splitLogic({ submitDate: startTime, splits })
      const staffComments = []
      await submitFunctions[functionName]({
        startTime,
        endTime,
        hourlyWage,
        splitDateTime1,
        splitDateTime2,
        splitDateTime3,
        splitHourlyWage1,
        splitHourlyWage2,
        splitHourlyWage3,
        isStartTimeBreakTime,
        isSplitDateTime1BreakTime,
        isSplitDateTime2BreakTime,
        isSplitDateTime3BreakTime,
        deletedReason,
        acceptedReason,
        rejectedReason,
        introductionHistory,
        introductionHistoryOther,
        staffComments,
        comment,
        isSendMail,
        doctorSubsidy,
        isPublished,
        isDoubleRecruitment,
      })
      if (functionName === 'cancel') {
        staffCreateActivity({
          variables: {
            activity: 'CANCEL_FIXED_SHIFTS',
            doctorId,
            details: `拠点：${clinicName}_${
              departmentNames[departmentName]
            } シフト日時：${moment(startTime).format(
              'YYYY年MM月DD日'
            )} ${moment(startTime).format('HH:mm')}~${moment(
              splits[splits.length - 1].date
            ).format('HH:mm')}`,
          },
        })
      }
      popup.success(successMessage)
      setSubmitting(false)
      resetForm()
      closeModal()
      if (functionName === reject || functionName === cancel) {
        history.push({
          pathname: `${parentPath.pathname}${Router.get(
            Router.staffCreateAvailableShift
          )}`,
          state: {
            clinicalDepartment,
            startTime,
            splits,
            isPublished,
          },
        })
      }
    } catch (error) {
      popup.error(error)
      setSubmitting(false)
    }
  }

  const { workSchedule, staffMe, departments } = data
  const doctorHourlyWageRange = workSchedule?.adjustHourlyWageRange

  if (!workSchedule) {
    return <NotFound />
  }

  const {
    updateActivityHistories,
    postActivityHistories,
  } = activityLogHistories(
    acData.activityHistories,
    activities,
    updateActivities
  )

  const departmentNames = clinicalDepartmentNames({
    departments,
  })
  return (
    <DetailWorkScheduleScene
      i18n={i18n}
      workSchedule={workSchedule}
      availableShifts={availableShiftComment.availableShifts}
      activitiesHistory={postActivityHistories ? postActivityHistories : []}
      postUpdateHistory={updateActivityHistories ? updateActivityHistories : []}
      staffMe={staffMe}
      onSubmit={onSubmit}
      accept={accept}
      cancel={cancel}
      doctorHourlyWageRange={doctorHourlyWageRange}
      reject={reject}
      restore={restore}
      update={update}
      isMoneyPermitted={isMoneyPermitted}
      isUpdatePermitted={isUpdatePermitted}
      isRejectPermitted={isRejectPermitted}
      isCancelPermitted={isCancelPermitted}
      isRestorePermitted={isRestorePermitted}
      isAcceptPermitted={isAcceptPermitted}
      isAddPermitted={isAddPermitted}
      isDeletePermitted={isDeletePermitted}
      isGoldWare={isGoldWare}
      departmentNames={departmentNames}
      countDoctorWorkedInClinic={noDoctorWorkedData?.countDoctorWorkedInClinic}
      popup={popup}
      isChangeConfirmedShiftsStatusPermitted={
        isChangeConfirmedShiftsStatusPermitted
      }
      isAddCommentPermitted={isAddCommentPermitted}
      state={state}
      isDeleteAllComment={isDeleteAllComment}
    />
  )
}

export default DetailWorkSchedule
