
import { Growl } from 'primereact/growl'
import React, { createContext, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import popupGenerator from './popupGenerator'

const CustomContext = createContext([])

export const CustomProvider = ({ children, setToken }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [menuToggle, setMenuToggle] = useState(null)
  const [activeIndexSecond, setActiveIndexSecond] = useState(null)
  const [activeIndexThird, setActiveIndexThird] = useState(null)
  const [activeIndexFour, setActiveIndexFour] = useState(null)
  const [activeIndexSix, setActiveIndexSix] = useState(null) 
  const [activeIndexFive, setActiveIndexFive] = useState(null)
  const [activeIndexSeven, setActiveSeven] = useState(null)
  const [isDoctorBlocked, setIsDoctorBlocked] = useState(null)
  const { i18n } = useTranslation()
  const growl = useRef(null)
  const popup = popupGenerator(growl, i18n)

  return (
    <CustomContext.Provider
      value={[
        {
          popup,
          i18n,
          activeIndex,
          menuToggle,
          activeIndexSecond,
          activeIndexThird,
          activeIndexFour,
          activeIndexSix,
          activeIndexFive,
          activeIndexSeven,
          isDoctorBlocked,
        },
        {
          setActiveIndex,
          setMenuToggle,
          setToken,
          setActiveIndexSecond,
          setActiveIndexThird,
          setActiveIndexFour,
          setActiveIndexSix,
          setActiveIndexFive,
          setActiveSeven,
          setIsDoctorBlocked,
        },
      ]}
    >
      <Growl ref={growl} className='p-growl' />
      {children}
    </CustomContext.Provider>
  )
}

export const useCustom = () => useContext(CustomContext)
