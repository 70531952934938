import gql from 'graphql-tag'

export const FILE_CATEGORIES_FRAGMENT = gql`
  fragment FILE_CATEGORIES_FRAGMENT on Query {
    fileCategories: enumValues(name: "FileCategory") {
      value
      description
    }
  }
`
export const FILE_CATEGORIES = gql`
  query FILE_CATEGORIES {
    ...FILE_CATEGORIES_FRAGMENT
  }
  ${FILE_CATEGORIES_FRAGMENT}
`

export const GENDERS_FRAGMENT = gql`
  fragment GENDERS_FRAGMENT on Query {
    genders: enumValues(name: "Gender") {
      value
      description
    }
  }
`

export const GENDERS = gql`
  query GENDERS {
    ...GENDERS_FRAGMENT
  }
  ${GENDERS_FRAGMENT}
`

export const DEPARTMENTS_FRAGMENT = gql`
  fragment DEPARTMENTS_FRAGMENT on Query {
    departments: enumValues(name: "DepartmentName") {
      value
      description
    }
  }
`

export const DEPARTMENTS = gql`
  query DEPARTMENTS {
    ...DEPARTMENTS_FRAGMENT
  }
  ${DEPARTMENTS_FRAGMENT}
`
export const WORK_PATTERNS_FRAGMENT = gql`
  fragment WORK_PATTERNS_FRAGMENT on Query {
    workPatterns: enumValues(name: "WorkPattern") {
      value
      description
    }
  }
`

export const WORK_PATTERNS = gql`
  query WORK_PATTERNS {
    ...WORK_PATTERNS_FRAGMENT
  }
  ${WORK_PATTERNS_FRAGMENT}
`

export const FILE_FRAGMENTS = gql`
  fragment FILE_FRAGMENTS on File {
    id
    description
    encoding
    fileCategory
    filename
    key
    mimetype
  }
`

export const PERMITTED_ACTION_FRAGMENT = gql`
  fragment PERMITTED_ACTION_FRAGMENT on Query {
    permittedActions: enumValues(name: "PermittedAction") {
      value
      description
    }
  }
`
export const API_GROUP_TYPE_FRAGMENT = gql`
  fragment API_GROUP_TYPE_FRAGMENT on Query {
    apiGroupTypes: enumValues(name: "ApiGroupType") {
      value
      description
    }
  }
`
export const JOIN_BACKGROUNDS_FRAGMENT = gql`
  fragment JOIN_BACKGROUNDS_FRAGMENT on Query {
    joinBackgrounds: enumValues(name: "JoinBackground") {
      value
      description
    }
  }
`

export const JOIN_BACKGROUNDS = gql`
  query JOIN_BACKGROUNDS {
    ...JOIN_BACKGROUNDS_FRAGMENT
  }
  ${JOIN_BACKGROUNDS_FRAGMENT}
`
export const QUESTION_CATEGORY = gql`
  query QuestionCategories($where: QuestionCategoryWhereInput) {
    questionCategories(where: $where) {
      id
      content
      createdAt
    }
  }
`
