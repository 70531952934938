import {
  DOCTOR_FRAGMENTS,
  DOCTOR_ME_FRAGMENT,
} from '@medical/layout/DoctorLayout/DoctorLayoutRequest.graphql'
import {
  DEPARTMENTS_FRAGMENT,
  GENDERS_FRAGMENT,
} from '@medical/libs/general.graphql'
import gql from 'graphql-tag'

export const DOCTOR_UPDATE_PROFILE_QUERY = gql`
  query {
    ...DOCTOR_ME_FRAGMENT
    ...GENDERS_FRAGMENT
    ...DEPARTMENTS_FRAGMENT
  }
  ${DOCTOR_ME_FRAGMENT}
  ${GENDERS_FRAGMENT}
  ${DEPARTMENTS_FRAGMENT}
`

export const DOCTOR_SKILLS = gql`
  query DoctorSkills($where: DoctorSkillWhereInput) {
    doctorSkills(where: $where) {
      id
      skill {
        id
        name
        isShow
        deletedAt
      }
      doctor {
        id
      }
    }
  }
`

export const DOCTOR_UPDATE_PROFILE = gql`
  mutation DOCTOR_UPDATE_PROFILE(
    $lastname: String!
    $lastnameKana: String!
    $firstname: String!
    $firstnameKana: String!
    $gender: Gender!
    $birthday: DateTime!
    $medicalLicenseNumber: String
    $departments: [DepartmentName!]!
    $phoneNumber: String!
    $stateOrRegion: String!
    $address1: String!
    $address2: String
    $zipCode: String!
    $nearestStations: [String!]
    $placeOfWork: String!
    $graduationUniversity: String!
    $graduationUniversityYear: Int!
    $obtainDoctorLicenseYear: Int!
    $professionalDescription: String
    $isJoinedSublingualImmunotherapyELearning: Boolean
    $isJoinedOnlineDiagnosticTraining: Boolean
    $introducedBy: String
    $careerHistories: [CareerHistoryForUpsert!]
    $skillSet: [ID!]
    $province: String
    $isUpdateAddress: Boolean
    $isCreateTransportationCost: Boolean
  ) {
    doctorUpdateProfile(
      lastname: $lastname
      lastnameKana: $lastnameKana
      firstname: $firstname
      firstnameKana: $firstnameKana
      gender: $gender
      birthday: $birthday
      medicalLicenseNumber: $medicalLicenseNumber
      departments: $departments
      phoneNumber: $phoneNumber
      stateOrRegion: $stateOrRegion
      address1: $address1
      address2: $address2
      zipCode: $zipCode
      nearestStations: $nearestStations
      placeOfWork: $placeOfWork
      graduationUniversity: $graduationUniversity
      graduationUniversityYear: $graduationUniversityYear
      obtainDoctorLicenseYear: $obtainDoctorLicenseYear
      professionalDescription: $professionalDescription
      isJoinedSublingualImmunotherapyELearning: $isJoinedSublingualImmunotherapyELearning
      isJoinedOnlineDiagnosticTraining: $isJoinedOnlineDiagnosticTraining
      introducedBy: $introducedBy
      careerHistories: $careerHistories
      skillSet: $skillSet
      province: $province
      isUpdateAddress: $isUpdateAddress
      isCreateTransportationCost: $isCreateTransportationCost
    ) {
      ...DOCTOR_FRAGMENTS
    }
  }
  ${DOCTOR_FRAGMENTS}
`

export const DOCTOR_CHANGE_EMAIL = gql`
  mutation DOCTOR_CHANGE_EMAIL($id: ID!, $email: String!, $newEmail: String!) {
    doctorChangedEmail(id: $id, email: $email, newEmail: $newEmail)
  }
`
